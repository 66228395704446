import { amrest } from '@/vbgauth/util/amrest-axios.js'
import { playrest } from '@/vbgauth/util/playrest-axios.js'
import forgotFlowConfig from '@/common/assets/json/forgot_flow_config.json'
import passwordConfig from '@/common/assets/json/password_config.json'
import translations from '@/common/assets/json/messages1.json'
import {iamvar_authErrorHeaderName, iamvar_enableCaptcha, iamvar_enableJCaptcha} from '../../../../public/const.js'
import { profilerest } from '@/vbgauth/util/profilerest-axios.js'
import List from '@/vbgauth/components/VbgLoginNew/common/BlockedList.js'
export default {
    namespaced: true,
    state: {
        passwordBlackListC: '',
        passwordBlackListE: '',
        callbacks: [
            {
                type: "NameCallback",
                output: [{
                    name: "prompt",
                    value: "User Name"
                }],
                input: [{
                    name: "IDToken1",
                    value: ""
                }]
            }
        ],
        pwdAuthOptions: [
            {
                available: false,
                disable: false,
                title: "My Verizon for Business App",
                subTitle: "We'll send a secure push notification to your business app so you can log in.",
                pagePath: "PwdLessPush",
                iconName:"cellPhone"
            },
            {
                available: true,
                disable: false,
                title: "Phone or Email",
                subTitle: "Use your phone number or email address to confirm it's you.",
                pagePath: "PwdLessVerify",
                iconName: "link"
            },
            {
                available: true,
                disable: false,
                title: "Password",
                subTitle: "Enter your password to access your account.",
                pagePath: "PwdLessPassword",
                iconName: "pdlock"
            }
        ],
        options: {
            headers: {
                'Accept-API-Version': 'resource=2.0',
                'protocol': '1.0'
            }
        },
        submitLoader: false,
        messages: translations,
        authError: false,
        usrName: '',
        forgotUsrnCaptchaKey: '',
        forgotPwdCaptchaKey: '',
        forgotFlConfig: null,
        formInputs: null,
        pwdReq: null,
        pwdReqMatch: [],
        noOfPwdReq: 0,
        userVerified: false,
        transactionId: null,
        vzigInstance: null,
        username: null,
        alerts: [],
        resetPwdSuccess: false,
        pwdHistoryReqMet: true,
        pwdReqCheckList: [ ],
        success: false,
        sanitizedPayload: { },
        forgotPwdSqaTransactionId: null,
        vbgFlow:true,
        isSmbUser: false,
        enhanceFpFlow: true,
        ivrffStatus: false,
        vzCarrier: false,
        secretQuestions: []

    },
    mutations: {
        setPasswordBlackListC(state, payload) {
            state.passwordBlackListC = payload
        },
        setPasswordBlackListE(state, payload) {
            state.passwordBlackListE = payload
        },
        setSuccess(state, payload) {
            state.success = payload
        },
        setFormData(state, payload) {
            state.callbacks = payload
        },
        setalert(state, payload) {
            state.alerts = payload
        },
        setLoader(state, payload) {
            state.submitLoader = payload
        },
        setAuthError(state, payload) {
            state.authError = payload
        },
        setusrName(state, payload) {
            state.usrName = payload
        },
        setForgotFlowConfig(state, payload) {
            state.forgotFlConfig = payload
        },
        setFormInput(state, payload) {
            state.formInputs = payload
        },
        setPwdRequirement(state, payload) {
            state.pwdReq = payload
            let pwdData = [];
            for(var i = 0; i < state.pwdReq.length; i++) {
                if(state.pwdReq[i].required) {
                    state.noOfPwdReq += 1
                }
                pwdData.push({
                    "required": state.pwdReq[i].required,
                    "matched": "unmatched"
                })
                // state.pwdReqMatch.push({
                //     "required": state.pwdReq[i].required,
                //     "matched": "unmatched"
                // })
            }
            state.pwdReqMatch = pwdData;
        },
        setNoOfPwdReq(state, payload) {
            state.noOfPwdReq = payload
        },
        setPwdReqMatch(state, payload) {
            state.pwdReqMatch = payload
        },
        setUserVerified(state, payload) {
            state.userVerified = payload
        },
        setTransactionId(state,payload) {
            state.transactionId = payload
        },
        createVZIGInstance( state, payload) {
            state.vzigInstance = new VZIGCVSCore(payload)
        },
        setUsername(state, payload) {
            state.username =  payload
        },
        setResetPwdSuccess(state, payload) {
            state.resetPwdSuccess = payload
        },
        setPwdHistoryReqMet(state, payload) {
            state.pwdHistoryReqMet = payload
        },
        setPwdReqCheckList(state, payload) {
            state.pwdReqCheckList = payload
        },
        setSanitizedPayload(state, payload) {
            state.sanitizedPayload = payload
        },

        setForgotPwdSqaTransactionId(state, payload) {
            state.forgotPwdSqaTransactionId = payload
            console.log("@@@ state.forgotPwdSqaTransactionId",state.forgotPwdSqaTransactionId);
        },
        setVbgFlow(state, payload){
            state.vbgFlow = payload
        },
        setIsSmbUser(state, payload){
            state.isSmbUser = payload
        },
        setEnhanceFpFlow(state, payload) {
            state.enhanceFpFlow = payload
        },
        setIvrffStatus(state, payload) {
            state.ivrffStatus = payload
        },
        setVZCarrier(state, payload) {
            state.vzCarrier = payload
        },
        setSecretQuestions(state, payload) {
            console.log("asdf","---------------------)()(", payload);
            // state.secretQuestions = payload
            state.pwdReq = payload
        }
    },
    actions: {

        forgotPwdSqaTransactionId({state, commit}, payload) {
            console.log("@@@ forgotPwdSqaTransactionId")
            commit('setForgotPwdSqaTransactionId', payload)
          },

          updateVbgFlow({state, commit}, payload){
            commit('setVbgFlow', payload)

          },

          resetState({state, commit}, payload) {
            state.submitLoader = false
            commit('setalert', [])
            commit('setUserVerified', false)
            commit('setResetPwdSuccess', false)
            // reseting the password req criteria's
            commit('setPwdReqMatch', [])
            commit('setNoOfPwdReq', 0)
        },
        readFormInputs ({commit, state}, payload) {
            var formInputs = state.forgotFlConfig[payload].form.inputs
            commit('setFormInput', formInputs)
            if(payload === 'pwd_reset_component') {
                if(state.enhanceFpFlow) {
                    commit('setPwdRequirement', state.forgotFlConfig.pwd_reset_component.updated_pwd_requirement)
                } else {
                    commit('setPwdRequirement', state.forgotFlConfig.pwd_reset_component.pwd_requirement)
                }
                commit('setPwdReqCheckList', state.forgotFlConfig.pwdCheckList)
            }else if (payload === "pwd_create_component"){
                if(state.enhanceFpFlow) {
                    commit('setPwdRequirement', state.forgotFlConfig.pwd_create_component.create_pwd_requirement)
                } else {
                    commit('setPwdRequirement', state.forgotFlConfig.pwd_reset_component.pwd_requirement)
                }
                commit('setPwdReqCheckList', state.forgotFlConfig.pwdCheckList)
            }
        },
        readConfig ({commit}, payload) {
            commit('setForgotFlowConfig', forgotFlowConfig[payload])
        },
        getUsernameCallback ({commit, dispatch, state}, payload) {
            commit('setAuthError', false)
            commit('setLoader', true)
            return amrest.post('json/realms/vzwmb/authenticate?authIndexType=service&authIndexValue=VBGUserService&noSession=true', {},  state.options).then(function (response) {
                var data = response.data
                data.callbacks = payload
                dispatch('submitUsername', data)
            }).catch((error) => {
                commit('setLoader', false)
                throw error
            })
        },
        updateFormData ({commit}, payload) {
            commit('setFormData', payload)
        },
        updateAlerts({commit}, payload) {
            commit('setalert', payload)
        },
        submitUsername({commit, state}, payload) {
            let reqUrl = 'json/realms/vzwmb/authenticate?authIndexType=service&authIndexValue=VBGUserService&noSession=true&goto='+state.gotoUrl
            return amrest.post(reqUrl, payload,  state.options).then(function (response) {
                // if success redirect to stepup flow
                commit('setUserVerified', true)
            }).catch((error) => {
                commit('setLoader', false)
                var errorData = error.response
                if(errorData.headers[iamvar_authErrorHeaderName]) {
                    var errorStatus = JSON.parse(errorData.headers[iamvar_authErrorHeaderName])
                    if (errorStatus.authStatus == false) {
                        commit('setAuthError', true)
                        setTimeout(function () {
                            commit('setAuthError', false)
                        }, 3000)
                    }
                }

                // log unhandled error
                let requestObject = { reqUrl: reqUrl, reqBody: payload, reqHeaders: state.options }
                let explanation = "This call should return the userID validation"

                let unhandledErrObj = { request: requestObject, response: error, explanation: explanation }

                dispatch('sendUnhandledErrorToBackend', unhandledErrObj)

                throw error
            })
        },
        updatePwdReqMatch({commit}, payload) {
            commit('setPwdReqMatch', payload)
        },
        updateUserVerified({commit}, payload) {
            commit('setUserVerified', payload)
        },
        submitUserDetails({commit, state}, payload) {
            return playrest.get('http://localhost:9002/username', payload).then(function (response) {
                commit('setUsername', response.data.userName)
                var alertMsg = state.messages.en.message["forgot_username_display"]
                var msg = [{msg: alertMsg.replace('$0', state.username), type:'success'}]
                commit('setalert', msg)
                commit('setUserVerified', true)
            }).catch((error) => {
                throw error
            })
        },
        setFFTransactionId({commit}, payload) {
            commit('setTransactionId', payload)
        },
        setFFUsername({state, commit, dispatch}, payload) {
            let successMsg = [{msg: state.messages.en.message["forgot_pd_successfully_verified"], type: 'success'}]
            // dispatch('forgotflow/updateAlerts', msg, {root:true})
            commit('setFpAlert', successMsg)
            dispatch('cvsstepup/updateFpAlerts', successMsg, {root:true})
            commit('setUsername', payload)
        },
        setPwdReqCheckList({commit}, payload) {
            commit('setPwdReqCheckList', payload)
        },
        getUsername({state, commit, dispatch}, payload) {
            var newPayload = {
                'transactionId' : state.transactionId,
               // 'email': payload
               //'vbgFlow' : state.vbgFlow ? true : (state.isSmbUser) ? true: false
               'vbgFlow' : state.vbgFlow
            }
            return playrest.post(iamvar_recoveryServ+'/return-username', newPayload).then(function (response) {
                if(response.data.responseCode === '00') {
                    dispatch('cvsstepup/setChangedSelectedOption', false, { root: true })
                    commit('setReturnUsernameSuccess', true)
                    var alertMsg = state.messages.en.message["forgot_username_display"]
                    var msg = [{msg: alertMsg.replace('$0', response.data.userName), type:'success'}]
                    dispatch('login/updateUnifiedLoginAlerts', msg, {root:true})
                    if(document.cookie.indexOf('X-VZ-C_APP') !== -1) {
                        var iamvar_appCookie = getCookie('X-VZ-C_APP')
                        if(iamvar_appCookie == 'MVB') {
                            let msg1 = '{"actionType":"metricsUpdate","txnName":"submitForgotUserName","status":"SUCCESS","desc":""}';
                            if (window.webkit != undefined) { // iOS
                                if (window.webkit.messageHandlers.mfAppInterface != undefined) {
                                    window.webkit.messageHandlers.mfAppInterface.postMessage(msg1);
                                }
                            }
                            if (window.mfAppInterface != undefined) { // Android
                                window.mfAppInterface.postMessage(msg1);
                            }
                        }
                    }
                   // dispatch('login/setAuthErrorUpdate', { errorMessage: alertMsg.replace('$0', response.data.userName) }, {root:true})
                    dispatch('login/setAlertTypeUpdate', 'success', {root:true})
                    dispatch('cvsstepup/updateCvsAuthAlert', { errorMessage: alertMsg.replace('$0', response.data.userName) }, {root:true})
                    dispatch('login/updateForgotUsername', response.data.userName , { root: true })
                    commit('setusrName', response.data.userName);
                } else {
                    commit('setReturnUsernameSuccess', false)
                }
            }).catch((error) => {
                throw error
            })
        },
        resetUserPassword({state, commit, dispatch}, payload) {
            // var newPayload = {
            //     // 'transactionId' : (state.vbgFlow ? state.transactionId : state.forgotPwdSqaTransactionId),
            //     'transactionId' : state.transactionId,
            //     'userName': state.username,
            //     'password': payload
            // }
            commit('setLoader', true)
            var newPayload = null
            var sanitizePayload = {
                'transactionId' : state.transactionId,
                'userName' : state.username
            }
            
            if(!state.vbgFlow) {
                newPayload = {
                    'transactionId' : state.ivrffStatus ? '' : state.transactionId,
                    'password': payload,
                    //'vbgFlow': state.isSmbUser ? true : false
                    'vbgFlow' : state.vbgFlow,
                    'vzCarrier': state.vzCarrier
                }
            } else {
                newPayload ={
                    'transactionId' : state.ivrffStatus ? '' : state.transactionId,
                    'password': payload,
                    'vbgFlow': true,
                    'vzCarrier': state.vzCarrier
                }
            }
            let option = state.options
            if(state.ivrffStatus) {
                option.headers['userverified'] = true
            }
            
            commit('sanitizePayloadForLogging', sanitizePayload)

            return playrest.post(iamvar_recoveryServ+'/change-password', newPayload, option).then(function (response) {
                if(response.data.responseCode === '00') {
                    dispatch('cvsstepup/setChangedSelectedOption', false, { root: true })
                    commit('setResetPwdSuccess', true)
                    // var alertMsg = state.messages.en.message["pd_reset_successful"]
                    // var alertMsgOauth = state.messages.en.message["pd_reset_successful_oauth"]
                    // var msg = [{msg: alertMsg, type:'success'}]
                    var alertMsg = state.messages.en.message["forgot_pd_reset_successfully"]
                    var msg = [{ msg: alertMsg, type: 'success' }]
                    var authMsg = { errorMessage: alertMsg }
                    if(document.cookie.indexOf('X-VZ-C_APP') !== -1) {
                        var iamvar_appCookie = getCookie('X-VZ-C_APP')
                        if(iamvar_appCookie == 'MVB') {
                            //msg = [{msg: alertMsgOauth, type:'success'}];
                            let msg1 = '{"actionType":"metricsUpdate","txnName":"submitNewPassword","status":"SUCCESS","desc":""}';
                            if (window.webkit != undefined) { // iOS
                                if (window.webkit.messageHandlers.mfAppInterface != undefined) {
                                    window.webkit.messageHandlers.mfAppInterface.postMessage(msg1);
                                }
                            }
                            if (window.mfAppInterface != undefined) { // Android
                                window.mfAppInterface.postMessage(msg1);
                            }
                        }
                    }
                    //dispatch('cvsstepup/updateFpAlerts', msg, {root:true})
                    dispatch('login/updatePwdAuthOptions', state.pwdAuthOptions , { root: true })
                    dispatch('login/updateUnifiedLoginAlerts', msg, {root:true})
                    dispatch('login/updatePasswordSuccess', 'success', { root: true })
                    dispatch('cvsstepup/updateCvsAuthAlert', authMsg, {root:true})
                    dispatch('login/setAlertTypeUpdate', 'success', { root: true })
                 //   dispatch('login/updateForgotUsername', state.username , { root: true })
                    state.ivrffStatus ?  dispatch('login/updateForgotUsername', response.data.userId , { root: true }) :   dispatch('login/updateForgotUsername', state.username , { root: true })
                    commit('setNoOfPwdReq', 0)
                    commit('setPwdReqMatch', [])
                } else if(response.data.responseCode === '9') {
                    commit('setResetPwdSuccess', false)
                    commit('setPwdHistoryReqMet', false)
                }
                dispatch('cvsstepup/updateSetPreviousOtpTransactionID', null, {root:true})
                dispatch('cvsstepup/updateDnrStatus', true, {root:true})

                // redirect the user if we get X-Mp-Error in response headers
                // This is for IVR Chagnes
                if(response.headers && response.headers['x-mp-error'] === "302") {
                    window.location.href = response.headers['location']
                }
                commit('setLoader', false)
            }).catch((error) => {
                // log unhandled error
                var errorData = error.response
                if(errorData.status === 400) {
                    if(errorData.data.responseCode === '28') {
                        let alertMsg = state.messages.en.message["pd_reset_history_error"]
                        let msg = [{ msg: alertMsg, type: 'error'}]
                        dispatch('cvsstepup/updateFpAlerts', msg, {root:true})
                    } else if(errorData.data.responseCode === '29') {
                        let alertMsg = state.messages.en.message["pd_reset_blacklist_error"]
                        let msg = [{ msg: alertMsg, type: 'error' }]
                        dispatch('cvsstepup/updateFpAlerts', msg, {root:true})
                    } else {
                        let msg = [{ msg: error.response.data.responseMessage, type: 'error'}]
                        dispatch('cvsstepup/updateFpAlerts', msg, {root:true})
                    }
                }
                let requestObject = { reqUrl: iamvar_recoveryServ+'/change-password', reqBody: state.sanitizedPayload, reqHeaders: state.options }
                let explanation = "This call should return if the new password is valid or not"

                let unhandledErrObj = { request: requestObject, response: error, explanation: explanation }

                dispatch('sendUnhandledErrorToBackend', unhandledErrObj)
                commit('setLoader', false)
                throw error
            })
        },

        // getPassswordBlackList({ commit, dispatch, state }, type) {
        //     var URL = "pre-auth/util-service/v1/get-password-block-list"
        //     return profilerest.get(URL).then(function (response) {
              
        //         commit('setPasswordBlackListC', response.data.passwordBlockList.passwordBlackListC)
        //         commit('setPasswordBlackListE', response.data.passwordBlockList.passwordBlackListE)
        //         if(response && response.headers && response.headers['reset_pwd_enabled'].toLowerCase() == 'true') {
        //             commit('setEnhanceFpFlow',true)
        //         } else {
        //             commit('setEnhanceFpFlow',false)
        //         }
        //         // type == "create" is coming from createpwd component of vcg-vbg migration project
        //         if(type == "reset_pass"){
        //             dispatch('readFormInputs','pwd_reset_component')
        //         }else{
        //             type == "create" ? dispatch('readFormInputs','pwd_create_component') : dispatch('readFormInputs','pwd_reset_component') 
        //         }
        //     }).catch(function (error) {
        //         if(type == "reset_pass"){
        //             dispatch('readFormInputs','pwd_reset_component')
        //         }else{
        //             type == "create" ? dispatch('readFormInputs','pwd_create_component') : dispatch('readFormInputs','pwd_reset_component') 
        //         }// log unhandled error
        //         let requestObject = { reqUrl: URL, reqBody: { }, reqHeaders: state.options }
        //         let explanation = "This call should return the password black list"

        //         let unhandledErrObj = { request: requestObject, response: error, explanation: explanation }

        //         dispatch('sendUnhandledErrorToBackend', unhandledErrObj)

        //         throw error
        //     })

        // },
        getPassswordBlackList({commit, dispatch, state}, type){
            commit('setPasswordBlackListC', List.BlackListC);
            commit('setPasswordBlackListE', List.BlackListE);
            commit('setEnhanceFpFlow',true);
            // type == "create" is coming from createpwd component of vcg-vbg migration project
            if(type == "reset_pass"){
                dispatch('readFormInputs','pwd_reset_component')
            } else{
                type == "create" ? dispatch('readFormInputs','pwd_create_component') : dispatch('readFormInputs','pwd_reset_component') 
            }
        
        },

        getSecretQuestions({ commit, state }) {
            console.log("callinggg----->");
            var URL ="pre-auth/util-service/v1/get-secret-questions"
            return profilerest.get(URL).then(function (response) {
                commit('setSecretQuestions', response.data.questions)
                console.log(response,"=====================================");
            }).catch(function (error) {
                // const data = {
                //     "questions": [
                //         {
                //             "id": "33",
                //             "question": "What was the first live concert you attended?"
                //         },
                //         {
                //             "id": "34",
                //             "question": "Where did you and your spouse first meet?"
                //         },
                //         {
                //             "id": "35",
                //             "question": "What was your favorite place to visit as a child?"
                //         },
                //         {
                //             "id": "36",
                //             "question": "What was the first name of your first roommate?"
                //         },
                //         {
                //             "id": "37",
                //             "question": "What is the name of a memorable place?"
                //         },
                //         {
                //             "id": "38",
                //             "question": "What is the first name of your best friend?"
                //         },
                //         {
                //             "id": "39",
                //             "question": "What was your favorite restaurant in college?"
                //         }
                //     ],
                //     "uidModified": false
                // }
                // commit('setSecretQuestions', data.questions)

                // console.log("error", error);
                // throw error
                // var errorMsg = getUpdatedMessage(error.response.headers['x-vz-error-msg'])
                // if( (error.response.status == 400 || error.response.status == 401 ) && error.response.headers['x-vz-error-msg'] ) {
                //     dispatch('login/updateUnlockMyAccountAlerts', [{type: 'error', msg: errorMsg}], { root: true })
                // }
            })
        },

        containsBlackListPassword: function ({ state, commit }, payload) {

            var blackListArrayE = '';
            var blackListArrayC = '';
            if (typeof state.passwordBlackListE != 'undefined' && state.passwordBlackListE != null
                && state.passwordBlackListE != "") {
                var str = state.passwordBlackListE
                if (!str) {
                    return;
                }
                if (typeof atob != 'undefined') {
                    blackListArrayE = window.atob(str).split('|');
                } else { // IE8, IE9
                    var decodedBytes = dojox.base64.decode(str);
                    blackListArrayE = String.fromCharCode.apply(String, decodedBytes).split('|')
                }



            }
            if (typeof state.passwordBlackListC != 'undefined' && state.passwordBlackListC != null
                && state.passwordBlackListC != "") {

                var str1 = state.passwordBlackListC
                if (!str1) {
                    return;
                }
                if (typeof atob != 'undefined') {
                    blackListArrayC = window.atob(str1).split('|');
                } else { // IE8, IE9
                    var decodedBytes = dojox.base64.decode(str1);
                    blackListArrayC = String.fromCharCode.apply(String, decodedBytes).split('|')
                }

            }
            var index = -1;
            var success1 = false;
            const updatedPayload = payload.toLowerCase();
            if (blackListArrayE != null && blackListArrayE != "") {
                // index = $.inArray(payload.toLowerCase(), blackListArrayE);
                // success1 = index != -1
                blackListArrayE.forEach((val)=> {
                    if(!success1 && updatedPayload === val.toLowerCase()) {
                        success1 = true;
                    }
                })

            }
            if (blackListArrayC != null && blackListArrayC != "") {
                blackListArrayC.forEach((val)=> {
                    if(!success1 && updatedPayload.includes(val.toLowerCase())) {
                        success1 = true;
                    }
                })
                // $.each(blackListArrayC, function (index1, value1) {
                //     if (!success1) {
                //         success1 = payload.toLowerCase().indexOf(value1.toLowerCase()) != -1;
                //     } else {

                //         success1 = true;
                //     }

                // });

            }
            commit('setSuccess', success1)

        },
        resetShowVerifyOtp({ commit },payload){
            commit("setShowVerifyOTP", payload);
        },
        sanitizePayloadForLogging({ commit }, payload) {
            // Make a copy of the payload
            let sanitizedPayload = payload
           // sanitizedPayload.password = "****";
            commit('setSanitizedPayload', sanitizedPayload)
        },
        updateIvrffStatus({ commit }, payload) {
            commit('setIvrffStatus', payload)
        },
        updateVZCarrier({ commit }, payload ) {
            commit('setVZCarrier', payload)
        }
    },
    

    getters: {
        formData: state => state.callbacks,
        authError: state => state.authError,
        usrName: state => state.usrName,
        submitLoader: state => state.submitLoader,
        forgotUsrnCaptchaKey: state => {
            return iamvar_forgotUsrnRecaptchaKey
        },
        forgotPwdCaptchaKey: state => {
            return iamvar_forgotPwdRecaptchaKey
        },
        forgotFlConfig: state => state.forgotFlConfig,
        formInputs: state => state.formInputs,
        pwdReq: state => state.pwdReq,
        pwdReqMatch: state => state.pwdReqMatch,
        noOfPwdReq: state => state.noOfPwdReq,
        userVerified: state => state.userVerified,
        alerts: (state) => state.alerts,
        username: state => state.username,
        resetPwdSuccess: (state) => state.resetPwdSuccess,
        iamvar_enableCaptcha: state => {
            return iamvar_enableCaptcha
        },
        iamvar_enableJCaptcha: state => {
            return iamvar_enableJCaptcha
        },
        pwdHistoryReqMet: (state) => state.pwdHistoryReqMet,
        pwdReqCheckList: (state) => state.pwdReqCheckList,
        passwordBlackListC: state => state.passwordBlackListC,
        passwordBlackListE: state => state.passwordBlackListE,
        success: state => state.success,
        forgotPwdSqaTransactionId: (state) => state.forgotPwdSqaTransactionId,
        vbgFlow:(state) => state.vbgFlow,
        isSmbUser: (state) => state.isSmbUser,
        enhanceFpFlow: (state) => state.enhanceFpFlow,
        ivrffStatus: (state) => state.ivrffStatus,
        vzCarrier: (state) => state.vzCarrier,
        secretQuestions: (state) => state.secretQuestions
    }
}
