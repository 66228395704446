<template>
  <div class="vbg-rsp-main-container">
    <div>
      <BlockUI v-if="isLoading || isOtpLoading" :url="url"></BlockUI>
      <div class="vbg-rsp-title-container">
        <h1 class="vbg-rsp-title-h1">
          {{ $t("message.reset_security_add_verification_method_title") }}
        </h1>
      </div>
      <div>
        <p v-html="summary_desc" />
      </div>
      <div class="vbg-rsp-form-container">
        <form @submit.prevent="onSubmit()">
          <fieldset class="vbg-rsp-dropdown-container1">
            <div class="vbg-rsp-dropdown-container">
              <label
                class="vbg-ff-input-label"
                for="otp-dropdown"
                :class="{ 'vbg-rsp-disabled-label': showVerifyOTP }"
              >
                {{ $t("message.login_otp_device_dropdown_label") }}
              </label>
              <div id="drpdn">
                <div
                  ref="dropdownRef"
                  class="row dropdown dropdown-toggle"
                  :class="{
                    open: showDropdown,
                    'vbg-rsp-disabled-select': showVerifyOTP,
                  }"
                  aria-haspopup="true"
                  aria-expanded="false"
                  @click="!showVerifyOTP && toggleDropdown()"
                  @keyup.enter="!showVerifyOTP && toggleDropdown()"
                  tabindex="1"
                >
                  <div class="dropDefaultLabel">
                    <a id="dropdownMenu1" aria-haspopup="true" role="button">
                      <span
                        class="padleft10"
                        :class="{ 'vbg-rsp-disabled-label': showVerifyOTP }"
                      >
                        {{ selectedOtpMethod.label }}
                      </span>
                    </a>
                  </div>
                  <img class="vbg-rsp-dropdown-cheveron" :src="downCaret" />
                  <ul
                    ref="dropdownListRef"
                    class="dropdown-menu col-xs-12 px-0"
                    aria-labelledby="dropdownMenu1"
                  >
                    <li
                      v-for="(method, index) in otpDeviceList.isSmbUser ? otpMethodsForSmb : otpMethods"
                      :key="method.id"
                      role="presentation"
                      v-bind:class="{
                        chosen: method.id === selectedOtpMethod.id,
                      }"
                      :id="method.id"
                      :tabindex="index"
                      @keyup.enter="deviceOptionSelected(method, 'enter')"
                      @keyup="deviceListkeyupHandler($event, index)"
                      @click="deviceOptionSelected(method, 'click')"
                      ref="selectDeviceList"
                    >
                      <a role="menuitem">
                        <span></span> {{ method.label }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="user-otp-input-container" v-if="selectedOtpType">
              <label
                class="vbg-ff-input-label"
                for="user-input-text"
                :class="{ 'vbg-rsp-disabled-label': showVerifyOTP }"
              >
                {{
                  selectedOtpType === "email"
                    ? "Email"
                    : "10-digit phone number"
                }}
              </label>

              <div class="vbg-rsp-icon-input-group">
                <input
                  id="user-input-text"
                  name="user-input-text"
                  @keydown.space.prevent
                  ref="userInputTextRef"
                  v-model="userInputText"
                  @input="validateUserInput()"
                  type="text"
                  class="vbg-rsp-input-field"
                  :class="{
                    'vbg-rsp-disabled-input': showVerifyOTP,
                    'vbg-rsp-input-warning': !isValidText && userInputText,
                    // 'vbg-rsp-input-success': isValidText,
                  }"
                  :disabled="showVerifyOTP"
                  tabindex="2"
                />
                <div
                  class="vbg-rsp-warning-icon"
                  v-if="!isValidText && userInputText"
                >
                  <img :src="warningIcon" />
                </div>

                <!--<div class="vbg-rsp-success-icon" v-if="isValidText">
                  <img :src="CheckIcon" />
                </div>-->
                <div v-if="selectedOtpType && userInputText && !isValidText">
                  <p class="vbg-rsp-warning-msg" v-html="getWarningMessage" />
                </div>
              </div>
            </div>
          </fieldset>
          <div class="vbg-sp-btn-continue">
            <button
              id="rsp-add-delivery-btn"
              name="rsp-add-delivery-btn"
              class="btn vbg-ff-btn-continue vbg-ff-btn-width140 vbg-rsp-add-btn spacing05"
              type="submit"
              :class="{
                'vbg-rsp-disabled-btn':
                  showVerifyOTP || disableDeliveryBtn || isLoading,
              }"
              :disabled="showVerifyOTP || disableDeliveryBtn || isLoading"
              tabindex="3"
            >
              Add delivery method
            </button>
            <button
              id="rsp-cancel-delivery-btn"
              name="rsp-cancel-delivery-btn"
              class="btn vbg-ff-btn-cancel vbg-ff-btn-width140 vbg-rsp-add-btn spacing05"
              @click="cancelRecoveryFlow()"
              type="button"
              :class="{ 'vbg-rsp-disabled-btn-cancel': showVerifyOTP }"
              :disabled="showVerifyOTP"
              tabindex="4"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
      <div v-if="showVerifyOTP" class="vbg-rsp-verify-method-container">
        <form @submit.prevent="OnOtpSubmit()">
          <h2 class="vbg-rsp-title-h2">Verify your delivery method</h2>
          <p>
            Please enter the one-time passcode you received at
            {{ maskedValue }}. <br />Passcode is valid for 10 minutes.
          </p>
          <div class="verify-input-container">
            <label class="vbg-ff-input-label" for="onepasscode"
              >Enter one-time passcode</label
            >
            <input
              type="password"
              id="onepasscode"
              name="onepasscode"
              v-model="onepasscode"
              minlength="6"
              maxlength="6"
              class="vbg-rsp-input-field"
              tabindex="5"
              @keypress="onlyNumber"
            />
            <div v-if="onepasscode.length !== 0 && onepasscode.length !== 6">
              <p class="vbg-rsp-warning-msg">
                Please enter only 6-digits Passcode
              </p>
            </div>
          </div>
          <div class="vbg-sp-btn-continue">
            <button
              class="btn vbg-ff-btn-continue vbg-ff-btn-width140 btn-verify spacing05"
              type="submit"
              :class="{
                'vbg-ff-disabled': isOtpLoading || onepasscode.length !== 6,
              }"
              :disabled="isOtpLoading || onepasscode.length !== 6"
              tabindex="6"
              id="rsp-verify-btn"
              name="rsp-verify-btn"
            >
              Verify
            </button>
            <button
              type="button"
              class="btn vbg-ff-btn-cancel vbg-ff-btn-width140 btn-verify spacing05"
              @click="cancelVerifyOtp()"
              tabindex="7"
              id="rsp-cancel-verify-btn"
              name="rsp-cancel-verify-btn"
            >
              Cancel
            </button>
          </div>

          <p class="vz-text-link resend-passcode-link">
            <a
              class="vz-text-link vbg-rsp-link"
              href="#"
              id="resendlink"
              @click="onSubmit()"
              tabindex="8"
            >
              Resend passcode
            </a>
          </p>
          <p class="vz-text-link cant-receive-passcode-link">
            <a
              class="vz-text-link vbg-rsp-link"
              href="#"
              @click="openModel()"
              id="launchInfo"
              tabindex="9"
            >
              I can't receive a one-time passcode
            </a>
          </p>
          <!-- popup for cannot receive one time passcode -->
          <div id="noAccessToOtpModal" class="modal fade">
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content modal-content-container">
                <div class="modal-container">
                  <div class="close-icon-container" tabindex="1">
                    <a data-dismiss="modal" @click="closeModal()">
                      <img
                        src="@/common/assets/styles/corp/images/close_black.png"
                        style="height: 20px; width: 20px"
                      />
                    </a>
                  </div>
                  <div class="window-content mar-bottom50">
                    <div>
                      <h2 class="martop0 marbottom20 modal-content-title">
                        Can't receive a one time passcode?
                      </h2>
                      <p class="modal-content-description">
                        Please try another delivery method, contact your company
                        administrator, or
                        <a
                          href="https://www.verizon.com/business/support/"
                          style="text-decoration: underline; cursor: pointer"
                          class="vbg-ff-focus"
                          target="_blank"
                          tabindex="2"
                          >visit our support page.</a
                        >
                      </p>
                    </div>
                  </div>

                  <div class="modal-btn-container martop100">
                    <button
                      type="button"
                      data-dismiss="modal"
                      @click="closeModal()"
                      class="btn primary_black marright20 martop50 oauth-close-button bttn-container vbg-rel-bottom-40  spacing05"
                      tabindex="3"
                      id="rsp-close-modal-btn"
                      name="rsp-close-modal-btn"
                    >
                      <span>Close</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { setPageDetails } from "../../../../public/adobeTagging";
import commonMixin from "@/common/mixin/commonMixins";
import warningIcon from "@/common/assets/styles/corp/images/warning.png";
import translations from "@/common/assets/json/messages1.json";
import CheckIcon from "@/common/assets/styles/corp/images/checkmark.png";
import downCaret from "@/common/assets/styles/corp/images/down-caret_blk.png";

export default {
  name: "ResetSecurityProfileAddOtpComponent",
  props: ["tokenVal"],
  components: {},
  data() {
    return {
      downCaret,
      CheckIcon,
      onepasscode: "",
      warningIcon: warningIcon,
      otpMethods: [
        { id: "text", label: "Text (US only)" },
        { id: "email", label: "Email" },
        { id: "call", label: "Call (US and Canada)" },
      ],
      otpMethodsForSmb: [
        { id: "text", label: "Text me" },
        { id: "call", label: "Call me (US and Canada only)" },
      ],
      userValidationInputClass: "",
      selectedOtpMethod: {
        id: "select-label",
        label: "Select",
        text: "Select",
      },
      selectedOtpType: "",
      userInputText: "",
      showDropdown: false,
      emailFormatRegex:
        /^[a-zA-Z0-9](\.?\_?\-?[a-zA-Z0-9]){0,}@[a-zA-Z0-9-_]+\.([a-zA-Z0-9-_]{1,}\.){0,}[a-zA-Z]{2,}$/,
      isValidText: false,
      whichRealm: true,
      validFormData: false,
      isLoading: false,
      isOtpLoading: false,
      url:
        iamvar_cdnUrl +
        iamvar_imgSourceContext +
        "/business/login/includes/circle-loaderWHITE_48x48.gif",
      summary_desc: translations.en.message['reset_security_add_verification_method_des'],
    };
  },
  mixins: [commonMixin],
  async created() {
    setPageDetails("Recovery Secure Profile: Add Delivery Method Page");
  },
  mounted() {
    this.changeContextBiocatch('rsp_add_delivery_method_page')
    /* Event Listener For Closing Select Box */
    document.addEventListener("click", (e) => {
      if (this.showDropdown && e.target.closest("#drpdn") === null) {
        this.showDropdown = false;
      }
    });
    this.$nextTick(() => this.$refs.dropdownRef.focus());
    //this.updateUrlOnMount(window.location.href);
  },
  methods: {
    ...mapActions("profile", [
      "sendotpToDevice",
      "updateGotoComponent",
      "resetShowVerifyOtp",
      "updateGotoOtpOvw",
      "verifyOtpCode",
      "updateGotoOtp",
    ]),
    ...mapActions("login", [ "updateUnlockMyAccountAlerts" ]),
    ...mapMutations("profile", ['setLoader']),

    closeModal() {
      $("#noAccessToOtpModal").modal("hide");
    },
    cancelRecoveryFlow() {
      this.updateGotoOtp(false);
      this.updateGotoOtpOvw(true);
      this.updateUnlockMyAccountAlerts([])
    },
    openModel() {
      $("#noAccessToOtpModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    },
    cancelVerifyOtp() {
      this.resetShowVerifyOtp(false);
      this.updateUnlockMyAccountAlerts([])
    },
    async onSubmit() {
      this.isLoading = true;
      try {
        const payload = {
          uuID: this.uuID,
        };
        if (this.selectedOtpType == "email") {
          payload.emailAddress = this.userInputText;
          payload.otpType = "email";
        } else if (this.selectedOtpType == "call") {
          payload.mtn = this.userInputText.replace(/-/g, "");
          payload.countryCode = "1";
          payload.otpType = "ivr";
        } else if (this.selectedOtpType == "text") {
          payload.mtn = this.userInputText.replace(/-/g, "");
          payload.countryCode = "1";
          payload.otpType = "sms";
        }
        this.setLoader(true);

        // submit addotpdevice request
        await this.sendotpToDevice(payload);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    async OnOtpSubmit() {
      this.isOtpLoading = true;
      try {
        const payload = {
          uuID: this.uuID,
          otpToken: this.onepasscode,
          emailAddress: "",
          mtn: "",
          countryCode: "1",
        };
        if (this.selectedOtpType == "email") {
          payload.emailAddress = this.userInputText;
          payload.deliveryMethodSelected = "email";
        } else if (this.selectedOtpType == "text") {
          payload.mtn = this.userInputText.replace(/-/g, "");
          payload.deliveryMethodSelected = "sms";
        } else if (this.selectedOtpType == "call") {
          payload.mtn = this.userInputText.replace(/-/g, "");
          payload.deliveryMethodSelected = "ivr";
        }
        this.setLoader(true);

        await this.verifyOtpCode(payload);
        this.isOtpLoading = false;
      } catch (error) {
        this.isOtpLoading = false;
      }
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    deviceOptionSelected(selDevice) {
      this.selectedOtpMethod = selDevice;
      this.selectedOtpType = selDevice.id;
      this.userInputText = "";
      this.isValidText = false;

      this.$nextTick(() => this.$refs.userInputTextRef.focus());
    },
    deviceListkeyupHandler(e, index) {
      // logic to move up and down in device list with up & down arrow
      const deviceList = this.$refs.selectDeviceList;
      switch (e.key) {
        case "ArrowUp":
          if (index > 0) {
            deviceList[index - 1].focus();
          }
          if (index <= 0) {
            deviceList[deviceList.length - 1].focus();
          }
          break;
        case "ArrowDown":
          if (index < deviceList.length - 1) {
            deviceList[index + 1].focus();
          } else {
            deviceList[0].focus();
          }
          break;
        default:
          break;
      }
    },
    acceptMobileNumber(payload) {
      let x = payload.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      let phoneNum = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");

      return phoneNum;
    },
    validateUserInput() {
      this.isValidText = false;
      if (this.selectedOtpType === "email") {
        if (!this.emailFormatRegex.test(this.userInputText)) {
          return;
        }
        this.isValidText = true;
      } else {
        this.userInputText = this.acceptMobileNumber(this.userInputText);
        if (this.userInputText.length === 12) {
          this.isValidText = true;
          return;
        }
      }
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },
  },
  computed: {
    ...mapGetters("login", ["redirectToSecProfilePage"]),
    ...mapGetters("profile", ["uuID", "maskedValue", "showVerifyOTP","otpDeviceList",]),
    formData: {
      get: function () {
        return this.$store.getters["login/formData"];
      },
      set: function (newVal) {
        this.$store.dispatch("login/updateFormData", newVal);
      },
    },
    disableDeliveryBtn() {
      if (this.selectedOtpType && this.isValidText) {
        return false;
      }

      return true;
    },
    getWarningMessage() {
      if (this.selectedOtpType === "email") {
        return translations.en.message["reset_security_invalid_email_err"];
      } else {
        return translations.en.message["reset_security_invalid_phone_err"];
      }
    },
  },
  watch: {
    redirectToSecProfilePage: function (newVal) {
      if (newVal) {
        window.location.href = newVal;
      }
    },
  },
};
</script>
<style scoped>
.dropDefaultLabel {
  padding-right: 0px;
  padding-left: 0;
  flex: 1;
}
label {
  display: block !important;
}
.row.dropdown.dropdown-toggle {
  align-items: center;
}
.user-otp-input-container {
  margin-top: 32px;
}
.verify-input-container {
  margin-top: 32px;
}

.resend-passcode-link {
  margin-top: 16px !important;
}
.cant-receive-passcode-link {
  margin-top: 24px !important;
}
.dropdown-menu > li > a {
  color: #000 !important;
  text-decoration: none !important;
}
.vbg-rsp-add-btn {
  height: 44px !important;
  line-height: 18px;
  font-size: 16px;
  padding: 12px;
}
.btn-verify {
  height: 44px !important;
  line-height: 20px;
  font-size: 16px;
  padding: 12px;
}
.vbg-rsp-warning-msg {
  height: auto !important;
  margin-top: 8px !important;
  font-family: 'VzNHGeTX-reg11' !important;
}
.vbg-rsp-link {
  font-size: 16px !important;
}
.vz-text-link {
  color: #000;
  margin-bottom: 10px !important;
  text-decoration: none !important;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
}
.btn:active,.btn:hover,.btn:focus {
  line-height: 18px;
  padding: 12px;
  font-size: 16px;
}
.padleft10 {
  padding-left: 12px !important;
}
.dropdown-menu > li > a{background:none!important;padding-left:8px}
.vbg-ff-input-label {
  font-family: 'VzNHGeTX-reg11' !important;
}

/* CSS only for desktop */
@media (min-width: 1025px) {
  .vbg-rsp-add-btn {
    width: 220px !important;
  }
  .btn-verify {
    width: 120px;
  }
}
@media (max-width: 1024px) {
  .dropdown-menu li.chosen > a {
    color: #000 !important;
  }
  .dropdown-menu li.chosen {
    border-left: none !important;
  }
  .dropdown-menu > li > a {
    text-decoration: none !important;
    font-weight: bold;
    color: #747676 !important;
    letter-spacing: 0;
    line-height: 16px;
    font-size: 16px;
    margin-left: 0px;
    padding-left: 12px !important;
  }
  .dropdown-menu li.chosen a {
    padding-left: 12px !important;
  }
  .resend-passcode-link {
    margin-top: 24px !important;
  }
  .vz-text-link {
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
  }
}
/* for mobile screen only */
@media (max-width: 768px) {
  .vbg-sp-btn-continue {
    display: flex;
    flex-direction: column-reverse;
  }
}

/* modal css */
.modal-button {
  margin-top: 37.34px;
  height: 44px;
  width: 188px;
}
.modal-content-container {
  position: relative;
  width: 640px;
  height: 431px !important;
  margin: 0 auto;
  padding: 48px !important;
}
.modal-content-title {
  height: auto;
  width: 100%;
  font-size: 32px;
  line-height: 32px;
  margin-top: 0px !important;
}
.modal-content-description {
  margin-top: 24px;
  height: 40px;
  width: 100%;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
}
.close-icon-container {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 14px;
}
.close-icon-container:focus {
  outline: 1px dashed #000;
  outline-offset: -12;
}
.bttn-container {
  height: 44px;
  width: 190px;
  border-radius: 22px;
  background-color: #000000;
  font-size: 16px !important;
  line-height: 18px !important ;
  padding: 12px !important;
  text-align: center;
}
.modal-open {
  padding-right: unset !important;
}
.window-content {
  height: 96px;
  width: 90%;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .modal-content {
    height: auto;
  }
}

@media (max-width: 640px) {
  .modal-content-container {
    width: 100%;
    height: 100% !important;
    padding: 0px !important;
  }
  .modal-container {
    height: 360px;
    padding: 69px 0px 0px 16px;
  }
  .modal-content-title {
    height: 58px !important;
    font-size: 24px;
    line-height: 24px;
    margin-top: 10px;
  }
  .modal-content-description {
    margin-top: 24px;
  }
  .modal-loader {
    margin-top: 62px;
  }
  .modal-btn-container {
    padding-right: 16px;
  }
  .close-icon-container {
    height: 48px;
    width: 48px;
    position: relative;
    float: right;
  }
  .close-icon-container {
    top: -12px;
  }
  .bttn-container {
    width: 100%;
  }
  .window-content {
    height: 142px;
    width: 90% !important;
  }
}
@media (max-width: 280px) {
  .modal-content-description {
    max-width: 260px;
  }
  .modal-content-title {
    height: auto !important;
  }
  .mar-bottom50 {
    margin-bottom: 60px;
  }
}
/* button align on popup desktop/tablet */
@media (min-width: 641px) {
  .vbg-rel-bottom-40 {
    position: absolute;
    bottom: 40px !important;
  }
}
</style>
