<template>
  <div v-if="showContent">
    <!-- <div v-if="getUnifiedLoginAlerts.length > 0">
      <div class="row" v-for="j in getUnifiedLoginAlerts" :key="j">
        <div class="col-sm-12">
          <div class="vbg-ff-messagesInfo" v-bind:class="getAlertType(j)">
            <div class="vbg-ff-msg-container">
              <div class="messageWrapper" style="display: flex;">
                <div class="messageIcon" style="padding:0px;margin-right: 12px;">
                  <div class="messageImage" :class="messageImage(j)"></div>
                </div>
                <p class="messageContentUn col-xs-11" v-if="j.type=='list'" v-html="j.msg" style="padding:0px;margin:0px">{{j.msg}}</p>
                <p class="messageContentUn col-xs-11" v-else-if="j.type == 'error' || j.type == 'warning'" style="padding:0px;margin:0px" v-html="j.msg">{{j.msg}}</p>
                <p class="messageContentUn col-xs-11" v-else style="padding:0px;margin:0px;color:#ffffff" v-html="j.msg">{{j.msg}}</p>
                <div class="closeMessage col-xs-1" style="padding:0px;margin-left:auto">
                  <img class="closeMessageImg" v-if="j.type=='list' || j.type == 'error' || j.type == 'warning'" src="@/common/assets/styles/corp/images/close_black.png" @click="deleteAlert(j)" />
                  <img class="closeMessageImg" v-else src="@/common/assets/styles/corp/images/clear_white.png" @click="deleteAlert(j)" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="vbg-rsp-main-container">
      <BlockUI v-if="submitLoader" :url="url"></BlockUI>
      <div class="vbg-rsp-title-container">
        <h1 class="vbg-rsp-title-h1">{{ $t("message.sqa_page_title") }}</h1>
      </div>
      <div>
        <p class="vbg-rsp-des">
          {{ $t("message.sqa_page_des")}}
        </p>
      </div>
      <div class="vbg-rsp-form-container">
        <form @submit.prevent="onSubmit()"> 
          <label class="vbg-ff-input-label" style="line-height: 16px;" v-if="challengeQuestionText == 'Select'">Secret question</label>
          <label class="vbg-ff-input-label" style="line-height: 16px;" v-else >Your secret question</label>
          <div ref="dropdownRef"
            class="row dropdown dropdown-toggle" 
            id="drpdn" v-bind:class="{ open: showDropdown }" 
            aria-haspopup="true" aria-expanded="false" 
            @click="toggleDropdown()" 
            @keyup.enter="toggleDropdown()" 
            tabindex="1"
          >
            <div class="text-flex1">
              <a id="dropdownMenu1" aria-haspopup="true" role="button"> 
                <span>{{challengeQuestionText}}</span>
              </a>
            </div>
            <img class="vbg-rsp-dropdown-cheveron" :src="downCaret" />
            <ul class="dropdown-menu col-xs-12 px-0" aria-labelledby="dropdownMenu1"> 
              <li 
                v-for="(question, index) in secretQuestions" 
                :key="question.id" role="presentation" :tabindex="index+1" 
                v-bind:class="{ chosen: (question.question === challengeQuestionText), 'last-item': (index === secretQuestions.length - 1)}"
                @keyup.enter="questionSelected(question, 'enter')"
                @keyup="listkeyupHandler($event, index)"
                @click="questionSelected(question, 'click')"
                @keydown.tab="handleTabKey"
                ref="userSelectQuestion"
              >
                <a role="menuitem" @click="selectQuestion(question)">
                  <span>
                    <span class="sqaText">{{question.question}}</span>
                  </span>
                </a>
              </li>
            </ul>
          </div>
          <div class="vbg-ff-input-container"> 
            <label class="vbg-ff-input-label padleft0 ">Answer</label>
            <div class="vbg-icon-input-group"> 
              <input 
                class="vbg-rsp-input-field"    
                :class="(!isValidText && userAnswer) ? 'vbg-rsp-input-warning' : ''"        
                v-model="userAnswer" 
                maxlength="40"
                @input="getbuttondisable"
                ref="submitButtonRef"
                :tabindex="secretQuestions.length+1"
                :type="isShow ? 'text' : 'password'" 
                id="rsp-sqa-input"
                name="rsp-sqa-input"
              />
              <div class="vbg-show-icon">
                <span v-if="!isShow && userAnswer" class="vbg-rsp-link" @click="isShow = !isShow">Show</span>
                <span v-if="isShow && userAnswer" class="vbg-rsp-link" @click="isShow = !isShow">Hide</span>
              </div>
              <div class="vbg-rsp-warning-icon" style="right: 62px; top: 11px;" v-if="!isValidText && userAnswer">
                <img :src="warningIcon" />
              </div>
            </div>
            <div v-if="!isValidText && userAnswer">
              <p class="vbg-rsp-warning-msg" style=" margin-top: 8px; height: auto; " v-html="getWarningMessage" />
            </div>
          </div>
          <div class="subtext"> 
              {{ $t("message.sqa_page_subtext")}}
          </div>
          <div  v-show="enableTC" class="row no-gutters"  style="margin-bottom:60px">
            <div class="col-md-12" style="display: flex;">
              <input 
                type="checkbox"
                name="tc-modal"
                v-model="status"
                value="accepted"
                class="check_box_border"
                unchecked-value="not_accepted"
                :tabindex="secretQuestions.length+2"
                @keyup.enter="toggleCheckBox( 'enter')"
                ref="checkboxRef"
                id="ch"
                @click="toggleCheckBox(click)"
              />
              <label  class="tc" for="tc-modal">
                I agree to the
                <a
                 @click="tcModal()"
                  id="tc-modal1" 
                  style="color: #000; text-decoration: underline; cursor: pointer;"
                  @keyup.enter="tcModal()"
                  :tabindex="secretQuestions.length+3"
                >Terms of Use</a>
              </label>
            </div>
          </div>
          <div>
            <div id="termModal" class="modal fade">
              <div class="modal-dialog modal-right">
                <div class="modal-content" style="overflow: hidden !important;">
                  <a
                    class="close-icon-container"
                    data-dismiss="modal"  @click="enable(false)"
                  >
                    <img src="@/common/assets/styles/corp/images/close_black.png"/>
                  </a>
                  <popupComponent/>
                  <div class="btn-block"> 
                    <button
                      type="button"
                      class="btn vbg-ff-btn-continue vbg-ff-btn-width140 spacing05"
                      @click="enable(true)"
                      id="accept"
                      name="accept"
                      data-dismiss="modal"
                    >
                      <span>Agree</span>
                    </button>
                    <button
                      type="button"
                      class="btn vbg-ff-btn-cancel vbg-ff-btn-width140 spacing05"
                      @click="enable(false)"
                      id="cancel"
                      name="cancel"
                      data-dismiss="modal"
                    >
                      <span>Cancel</span>
                    </button>
                  </div>
                </div>
              
              </div>
            </div>
          </div>
          <div class="vbg-sp-btn-continue"> 
            <button
            :disabled="disableButton"
            :tabindex="secretQuestions.length+4"
            class="btn vbg-ff-btn-continue vbg-ff-btn-width140 spacing05"
            :class="disableButton ? 'vbg-ff-disabled' : ''"
            ref="continueButton"
            type="submit"
            id="rsp-sqa-continue-btn"
            name="rsp-sqa-continue-btn"
            > 
            Continue
            </button>
          </div>
        </form>
      </div>

    </div>

  </div>
</template>

<script>

import { mapGetters, mapActions, mapMutations } from "vuex";
import {setPageDetails,eventTracking,errorSet} from '../../../../public/adobeTagging';
import downCaret_blk from "@/common/assets/styles/corp/images/down-caret_blk.png";
import warningIcon from '@/common/assets/styles/corp/images/warning.png'
import popupComponent from "@/common/components/TermsPopupComponent"
import commonMixin from "@/common/mixin/commonMixins";


export default {
  name: "ResetSecurityProfileSqaComponent",
  components: { popupComponent },
  mixins: [commonMixin],
  mounted() {
    this.changeContextBiocatch('rsp_choose_sqa_page')
    this.getSecretQuestions()
    if(document.cookie.indexOf('X-VZ-C_APP') !== -1) {
      var iamvar_appCookie = getCookie('X-VZ-C_APP')
      if(iamvar_appCookie == 'MVB') {
        this.whichRealm = false;
      }
    }
    // window.onpopstate = event => {
    //   console.log(event)
    //   if (window.location.pathname.includes('business/profile/preauth/intercept')) {
    //     history.back();
    //   }
    // };
    // Rendering the component based on uuID . So it will prevent the route to render in other tab without ID without id.
    if(this.uuID === "") {
      this.showContent = false;
    }
    this.$refs.dropdownRef.focus()
    document.addEventListener('click', (e) => {
      if(this.showDropdown && e.target.closest("#drpdn") === null) {
        if(iamvar_appCookie == 'MVB') {
            this.showDropdown = true;
          }
          else {
            this.showDropdown=false
          }
      }
    })
    
  },
  data() {
    return {
      status: false,
      whichRealm: true,
      url: iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/circle-loaderWHITE_48x48.gif',
      challengeQuestion: "",
      challengeQuestionText: "Select",
      showDropdown: false,
      userAnswer: '',
      invalidCharRegex: /[@()_~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/,
      isShow: false,
      // disableButton: true,
      showContent: true,
      downCaret: downCaret_blk,
      warningIcon: warningIcon,
      status: false,
      isValidText: false
    };
  },

  methods: {
    ...mapActions("profile", ["displayLoginIntercept", "saveSQA", "getSecretQuestions"]),
    ...mapActions("login", [ "updateUnlockMyAccountAlerts" ]),
    ...mapMutations("profile", ['setLoader']),
    onSubmit() {
      var tcAccepted = false;
      if (this.status === true) {
        tcAccepted = true;
      }
      let payload = {
        uuID: this.uuID,
        sqaTO: [
          {
            id: this.challengeQuestion,
            pwd: this.userAnswer
          },
        ],
        tcAccepted: tcAccepted,
      };
      eventTracking('Set SQA Initited','');
      this.setLoader(true);
      this.saveSQA(payload);
    },
    selectQuestion(selQuestion) {
      if (selQuestion != null) {
        this.challengeQuestion = selQuestion.id;
        this.challengeQuestionText = selQuestion.question;
      }
    },
    listkeyupHandler(e, index) {
      // logic to move up and down in device list with up & down arrow
      const questionList = this.$refs.userSelectQuestion;
      switch(e.key) {
        case 'ArrowUp':
          if (index >= 1) {
            console.log(index)
            questionList[index-1].focus();
          } 
          if (index < 1) {
            questionList[questionList.length-1].focus();
          }
        break;
        case 'ArrowDown':
          if (index < questionList.length - 1) {
            questionList[index+1].focus();
          } else {
            questionList[0].focus();
          }
        break;
        default:
        break;
      }      
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown
    },
    messageImage(index) {
      if(index.type == 'error' || index.type == 'warning' || index.type == 'list') {
        return "newErrorMessageImage"
      } else {
        return index.type+"MessageImage";
      }
    },
    questionSelected( selectedQuestion, selectionType ) {
      if(selectionType === 'enter') {
        // move focus to button if user 
        // click enter after selecting an option from dropdown

        this.challengeQuestion = selectedQuestion.id;
        this.challengeQuestionText = selectedQuestion.question
        this.$nextTick(() => this.$refs.submitButtonRef.focus())
      } else {
        // dont move focus to button if user select from mouse
        this.$refs.dropdownRef.focus()
      }
    },
    handleTabKey(event) {
      const dropdownList = this.$refs.userSelectQuestion;
      
      //Checking whether the pressed key is tab and the focused element is the last element to close the dropdown
      if(event.key === 'Tab' && document.activeElement.classList.contains('last-item')) {
        this.toggleDropdown()
      }
    },
    tcModal() {
      $("#termModal").modal({
          backdrop: "static",
        });
         $('#tc-modal').prop('checked', false);
    },
    enable(status) {
      if (status ) {
        this.status = true;
      } else {
        this.status = false;
      }
    },
    toggleCheckBox( selectiontype ) {
      if(selectiontype === 'enter') {
        this.$nextTick(() => this.$refs.continueButton.focus())
      } else{
        this.$refs.checkboxRef.focus()
      }
      this.status = !this.status;
      
    },
    getbuttondisable() {
      this.isValidText = false
      if(this.userAnswer.length >= 3 && !(this.invalidCharRegex.test(this.userAnswer)) && this.challengeQuestionText != "Select" ) {
        this.isValidText = true
      }
    }
  },

  computed: {
    ...mapGetters('login',[
      'getUnifiedLoginAlerts',
      'redirectToSecProfilePage',
      'submitLoader',
    ]),
    ...mapGetters("profile", [
      'secretQuestions','uuID',"gotoSQA","gotoOtp","enableTC","migContent","gotoOtpOvw","firstName", "lastName",
    ]),
    formData: {
      get: function () {
          return this.$store.getters['login/formData'];
      },
      set: function (newVal) {
          this.$store.dispatch('login/updateFormData', newVal);
      }
    },
    // disableButton() {
    //   return (this.userAnswer.length >= 3 && !(this.invalidCharRegex.test(this.userAnswer)) && this.challengeQuestionText != "Select") ? false : true
    // },
    getWarningMessage() {
      if(this.userAnswer.length < 3){
        return 'Your answer must be 3-40 characters.'
      } else if (this.invalidCharRegex.test(this.userAnswer)) {
        return 'Only letters, numbers, spaces, and periods are allowed.'
      } else if (!this.userAnswer) {
        return 'Please enter a valid secret answer.'
      }
    },
    disableButton() {
      //return (this.challengeAnswer.length >= 3 && !(this.invalidCharRegex.test(this.challengeAnswer)) && this.challengeQuestion != "Select" && this.enabletc ? this.status : "") ? false : true
      
      if(this.enableTC) {
        if(this.userAnswer.length >= 3 && !(this.invalidCharRegex.test(this.userAnswer)) && this.challengeQuestionText != "Select" && this.status) {
          return false
        }
      } else {
        if(this.userAnswer.length >= 3 && !(this.invalidCharRegex.test(this.userAnswer)) && this.challengeQuestionText != "Select" ) {
          return false
        }
      }

      return true
    }
  },

  watch: {
    isShow: function(newVal) {
      if(newVal) {
        setTimeout(()=> {
            this.isShow = false
        },10000)
      }
    },
    gotoSQA: function (newVal) {
      if (newVal) {
        this.$router.push({ path: 'setsqa' })
      }
    },
    gotoOtp: function (newVal) {
      if (newVal) {
        this.$router.push({ path: 'addotpdevice' })
      }
    },
    gotoOtpOvw: function (newVal) {
      if (newVal) {
        this.updateGotoComponent('resetSecurityProfileOTPSummary');
      }
    },
  }
}
</script>

<style scoped>
#dropdownMenu1 span {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16.8px;
}
.messageContentUn {
  color: #000000;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
}
.dropdown {
  width: 596px !important;
  min-height: 44px !important;
  padding: 7px 14.6px 7px 12px !important;
  height: 44px !important;
  font-size: 16px;
}
.dropdown-arrow {
  /* background-size: 14px;
  background-position-x: 95%;
  background-position-y: 8px;
  width: 40px;
  height: 22px;
  padding: 0px;
  text-decoration: none;
  border-bottom: none; */
  position: absolute; 
  right: 0px;
  bottom: 11px;
  width: 32.33px;
}
.dropdown-menu {
  box-shadow: none !important;
}
.dropdown-menu li {
  border-bottom: 1px solid #CDCCCB;
}
.sqaText {
  white-space: normal;
}
.vbg-ff-input-container{
  margin-top: 24px !important;
  margin-bottom: 24px !important;
  display: flex;
  flex-direction: column;
}
.vbg-icon-input-group{
  width: 596px !important;
  position: relative;
}
.vbg-show-icon {
  height: 20px;
  width: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 12px;
  /* text-decoration: underline; */
  cursor: pointer;
  letter-spacing: 0.5px;
  font-size: 16px !important;
}
.vbg-rsp-input-field {
  width: 596px !important;
}
.subtext {
  font-size: 11px;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 12px !important;
  color: #000;
  font-family: 'VzNHGeTX-reg11' !important;
}
.dropdown-menu li.chosen {
  border-left: 4px solid #D52B1E;
}
.text-flex1 { 
  flex: 1;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.dropdown-menu > li > a {
  color: #000 !important;
  text-decoration: none !important;
  font-size: 16px !important;
  letter-spacing: 0;
  line-height: 16px;
}
.vbg-rsp-dropdown-cheveron {
  margin-right: 0px !important;
}
.check_box_border[type="checkbox"]:checked{
  accent-color: #000 !important;
}
.modal-dialog {
  margin: 0px !important;
  max-width: 739px;
  width: 100% !important;
  height: -webkit-fill-available !important;
}
.close-icon-container {
  padding: 0px;
  position: absolute;
  right: 34px;
  top: 34px;
  height: 20px;
  z-index: 1;
}
.modal-content {
  height: 100% !important;
  overflow: scroll !important;
  width: 100% !important;
  padding: 0px !important;
}
.btn-block {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #FFFFFF;
  box-shadow: 0 2px 8px 0 rgba(0,0,0,0.5);
  padding: 16px 16px 48px 40px !important;
}
input[type="checkbox"] { 
  position: relative;
  left: 0em;
  height: 20px;
  width: 20px;
}
input[type="checkbox"] + label {
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
  font-family: VzNHGeDS-reg11;
  margin-bottom: 0px;
}
.modal-open .modal {
  display: flex !important;
  align-items: end;
  justify-content: end;
  padding: 0px !important;
}
#ch {
  margin: 0px 10px 0px 0px !important;
}
.vbg-rsp-des {
  max-width: 371px;
  line-height: 16px;
  color: #000000 !important;
}
.vbg-ff-btn-width140 {
  height: 44px !important;
  line-height: 18px;
  font-size: 16px;
  padding: 12px;
  text-align: center;
}
.vbg-ff-input-label {
  font-family: 'VzNHGeTX-reg11' !important;
}
.vbg-rsp-warning-msg {
  font-family: 'VzNHGeTX-reg11' !important;
}
@media (max-width: 1024px) { 
  .vbg-icon-input-group{
    width: 100% !important;
  }
  .dropdown {
    width: 100% !important;
  }
  .vbg-rsp-input-field {
    width: 100% !important;
  }
  .dropdown {
    min-height: 44px !important;
    height: auto !important;
  }
  .dropdown-menu li.chosen > a {
    color: #000 !important;
    padding-left: 12px !important;
  }
  .dropdown-menu li.chosen {
    border-left: none !important;
  }
  .dropdown-menu > li > a {
    text-decoration: none !important;
    font-weight: bold;
    color: #747676 !important;
    margin-left: 0px;
    padding-left: 12px !important;
  }
}
@media (max-width: 768px) { 
  .modal-content { padding: 0px !important; }
  .close-icon-container { 
    right: 14px;
    top: 62px;
    z-index: 1;
  }
  .btn-block {
    display: flex;
    /* -ms-flex-direction: column-reverse; */
    flex-direction: column-reverse;
    padding: 16px 16px 48px !important;
  }
}
@media (max-width: 425px) {
  .messageContent,
  .messageContentUn {
    width: 232px;
  }
}
@media (max-width: 320px) {
  .messageWrapper {
    display: flex;
  }
}
@media (min-width: 280px) {
  .closeMessageImg {
    position: static !important;
  }
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
.row.dropdown.dropdown-toggle {
  -ms-flex-align: center;
  align-items: center;
}
</style>