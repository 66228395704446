var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"new_pwdless_box_container"},[_c('div',{staticClass:"new_pwdless_content"},[_c('div',[_c('h1',{staticClass:"new_pwdless_main_heading pwdles_main_heading"},[_vm._v(" "+_vm._s(_vm.$t("message.login_identity_verification_addOTP_title"))+" ")])]),_c('div',{staticClass:"new_pwdless_sub_heading pwdles_sub_heading"},[_vm._v(" "+_vm._s(_vm.$t("message.add_otp_description"))+" ")]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit()}}},[_c('div',{staticClass:"new_pwdless_input_label"},[_vm._v(" "+_vm._s(_vm.$t("message.login_otp_device_dropdown_label"))+" ")]),_c('div',{attrs:{"id":"drpdn"}},[_c('div',{ref:"dropdownRef",staticClass:"row dropdown dropdown-toggle",class:{
            open: _vm.showDropdown,
            'vbg-rsp-disabled-select': _vm.showVerifyOTP,
          },attrs:{"aria-haspopup":"true","aria-expanded":"false","tabindex":"1"},on:{"click":function($event){!_vm.showVerifyOTP && _vm.toggleDropdown()},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;!_vm.showVerifyOTP && _vm.toggleDropdown()}}},[_c('div',{staticClass:"dropDefaultLabel"},[_c('a',{attrs:{"id":"dropdownMenu1","aria-haspopup":"true","role":"button"}},[_c('span',{staticClass:"padleft10",class:{ 'vbg-rsp-disabled-label': _vm.showVerifyOTP }},[_vm._v(" "+_vm._s(_vm.selectedOtpMethod.label)+" ")])])]),_c('img',{staticClass:"vbg-rsp-dropdown-cheveron",attrs:{"src":_vm.downCaret}}),_c('ul',{ref:"dropdownListRef",staticClass:"dropdown-menu col-xs-12 px-0",attrs:{"aria-labelledby":"dropdownMenu1"}},_vm._l((_vm.otpDeviceList.isSmbUser ||
              _vm.getShowMTNDevice
                ? _vm.otpMethodsForSmb
                : _vm.otpMethods),function(method,index){return _c('li',{key:method.id,ref:"selectDeviceList",refInFor:true,class:{
                chosen: method.id === _vm.selectedOtpMethod.id,
              },attrs:{"role":"presentation","id":method.id,"tabindex":index},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.deviceOptionSelected(method, 'enter')},function($event){return _vm.deviceListkeyupHandler($event, index)}],"click":function($event){return _vm.deviceOptionSelected(method, 'click')}}},[_c('a',{attrs:{"role":"menuitem"}},[_c('span',{staticClass:"del_item_label"},[_vm._v(" "+_vm._s(method.label))])])])}),0)])]),(_vm.selectedOtpType)?_c('div',{staticClass:"user-otp-input-container"},[_c('label',{staticClass:"vbg-ff-input-label",class:{ 'vbg-rsp-disabled-label': _vm.showVerifyOTP },attrs:{"for":"user-input-text"}},[_vm._v(" "+_vm._s(_vm.selectedOtpType === "email" ? "Email" : "10-digit phone number")+" ")]),_c('div',{},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userInputText),expression:"userInputText"}],ref:"userInputTextRef",staticClass:"new_pwdless_input_field",class:{
              'vbg-rsp-disabled-input': _vm.showVerifyOTP,
              'vbg-rsp-input-warning': !_vm.isValidText && _vm.userInputText,
              // 'vbg-rsp-input-success': isValidText,
            },attrs:{"id":"user-input-text","name":"user-input-text","type":"text","disabled":_vm.showVerifyOTP,"tabindex":"2"},domProps:{"value":(_vm.userInputText)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;$event.preventDefault();},"input":[function($event){if($event.target.composing)return;_vm.userInputText=$event.target.value},function($event){return _vm.validateUserInput()}]}}),(!_vm.isValidText && _vm.userInputText)?_c('div',{staticClass:"vbg-rsp-warning-icon"},[_c('img',{attrs:{"src":_vm.warningIcon}})]):_vm._e(),(_vm.selectedOtpType && _vm.userInputText && !_vm.isValidText)?_c('div',[_c('p',{staticClass:"vbg-rsp-warning-msg",domProps:{"innerHTML":_vm._s(_vm.getWarningMessage)}})]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"pwd_less_btn_container submit_btn_spacing"},[_c('div',{staticClass:"new_pwdless_btn_section"},[_c('button',{staticClass:"new_pwdless_submit_btn",class:{
              'vbg-rsp-disabled-btn':
                _vm.showVerifyOTP || _vm.disableDeliveryBtn || _vm.submitLoader,
            },attrs:{"type":"submit","disabled":_vm.showVerifyOTP || _vm.disableDeliveryBtn || _vm.submitLoader,"tabindex":"3"}},[_vm._v(" Add delivery method ")])]),_c('div',{staticClass:"new_pwdless_btn_section_mobile"},[_c('button',{staticClass:"new_pwdless_submit_btn pwd_cancel_btn",class:{ 'vbg-rsp-disabled-btn-cancel': _vm.showVerifyOTP },attrs:{"type":"button","disabled":_vm.showVerifyOTP,"tabindex":"4"},on:{"click":function($event){return _vm.cancelRecoveryFlow()}}},[_vm._v(" Cancel ")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }