<template>
  <div class="new_pwdless_box_container">
    <div class="new_pwdless_content">
      <div>
        <h1 class="new_pwdless_main_heading pwdles_main_heading">
          {{ $t("message.login_identity_verification_addOTP_title") }}
        </h1>
      </div>
      <div class="new_pwdless_sub_heading pwdles_sub_heading">
        {{ $t("message.add_otp_description") }}
      </div>
      <form @submit.prevent="onSubmit()">
        <div class="new_pwdless_input_label">
          {{ $t("message.login_otp_device_dropdown_label") }}
        </div>
        <div id="drpdn">
          <div
            ref="dropdownRef"
            class="row dropdown dropdown-toggle"
            :class="{
              open: showDropdown,
              'vbg-rsp-disabled-select': showVerifyOTP,
            }"
            aria-haspopup="true"
            aria-expanded="false"
            @click="!showVerifyOTP && toggleDropdown()"
            @keyup.enter="!showVerifyOTP && toggleDropdown()"
            tabindex="1"
          >
            <div class="dropDefaultLabel">
              <a id="dropdownMenu1" aria-haspopup="true" role="button">
                <span
                  class="padleft10"
                  :class="{ 'vbg-rsp-disabled-label': showVerifyOTP }"
                >
                  {{ selectedOtpMethod.label }}
                </span>
              </a>
            </div>
            <img class="vbg-rsp-dropdown-cheveron" :src="downCaret" />
            <ul
              ref="dropdownListRef"
              class="dropdown-menu col-xs-12 px-0"
              aria-labelledby="dropdownMenu1"
            >
              <li
                v-for="(method, index) in otpDeviceList.isSmbUser ||
                getShowMTNDevice
                  ? otpMethodsForSmb
                  : otpMethods"
                :key="method.id"
                role="presentation"
                v-bind:class="{
                  chosen: method.id === selectedOtpMethod.id,
                }"
                :id="method.id"
                :tabindex="index"
                @keyup.enter="deviceOptionSelected(method, 'enter')"
                @keyup="deviceListkeyupHandler($event, index)"
                @click="deviceOptionSelected(method, 'click')"
                ref="selectDeviceList"
              >
                <a role="menuitem">
                  <span class="del_item_label"> {{ method.label }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="user-otp-input-container" v-if="selectedOtpType">
          <label
            class="vbg-ff-input-label"
            for="user-input-text"
            :class="{ 'vbg-rsp-disabled-label': showVerifyOTP }"
          >
            {{
              selectedOtpType === "email" ? "Email" : "10-digit phone number"
            }}
          </label>

          <div class="">
            <input
              id="user-input-text"
              name="user-input-text"
              @keydown.space.prevent
              ref="userInputTextRef"
              v-model="userInputText"
              @input="validateUserInput()"
              type="text"
              class="new_pwdless_input_field"
              :class="{
                'vbg-rsp-disabled-input': showVerifyOTP,
                'vbg-rsp-input-warning': !isValidText && userInputText,
                // 'vbg-rsp-input-success': isValidText,
              }"
              :disabled="showVerifyOTP"
              tabindex="2"
            />

            <div
              class="vbg-rsp-warning-icon"
              v-if="!isValidText && userInputText"
            >
              <img :src="warningIcon" />
            </div>

            <div v-if="selectedOtpType && userInputText && !isValidText">
              <p class="vbg-rsp-warning-msg" v-html="getWarningMessage" />
            </div>
          </div>
        </div>
        <div class="pwd_less_btn_container submit_btn_spacing">
          <div class="new_pwdless_btn_section">
            <button
              class="new_pwdless_submit_btn"
              type="submit"
              :class="{
                'vbg-rsp-disabled-btn':
                  showVerifyOTP || disableDeliveryBtn || submitLoader,
              }"
              :disabled="showVerifyOTP || disableDeliveryBtn || submitLoader"
              tabindex="3"
            >
              Add delivery method
            </button>
          </div>
          <div class="new_pwdless_btn_section_mobile">
            <button
              class="new_pwdless_submit_btn pwd_cancel_btn"
              @click="cancelRecoveryFlow()"
              type="button"
              :class="{ 'vbg-rsp-disabled-btn-cancel': showVerifyOTP }"
              :disabled="showVerifyOTP"
              tabindex="4"
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { setPageDetails } from "../../../../../public/adobeTagging";
import commonMixin from "@/common/mixin/commonMixins";
import warningIcon from "@/common/assets/styles/corp/images/warning.png";
import translations from "@/common/assets/json/messages1.json";
import CheckIcon from "@/common/assets/styles/corp/images/checkmark.png";
import downCaret from "@/common/assets/styles/corp/images/down-caret_blk.png";

export default {
  name: "NewAddOTPComponent",
  props: ["tokenVal"],
  components: {},
  data() {
    return {
      downCaret,
      CheckIcon,
      onepasscode: "",
      warningIcon: warningIcon,
      otpMethods: [
        { id: "text", label: "Text (US only)" },
        { id: "email", label: "Email" },
        { id: "call", label: "Call (US and Canada)" },
      ],
      otpMethodsForSmb: [
        { id: "text", label: "Text (US only)" },
        { id: "call", label: "Call (US and Canada)" },
      ],
      userValidationInputClass: "",
      selectedOtpMethod: {
        id: "select-label",
        label: "Select a delivery method",
        text: "Select",
      },
      selectedOtpType: "",
      userInputText: "",
      showDropdown: false,
      emailFormatRegex:
        /^[a-zA-Z0-9](\.?\_?\-?[a-zA-Z0-9]){0,}@[a-zA-Z0-9-_]+\.([a-zA-Z0-9-_]{1,}\.){0,}[a-zA-Z]{2,}$/,
      isValidText: false,
      whichRealm: true,
      validFormData: false,
      url:
        iamvar_cdnUrl +
        iamvar_imgSourceContext +
        "/business/login/includes/circle-loaderWHITE_48x48.gif",
    };
  },
  mixins: [commonMixin],
  async created() {
    setPageDetails("Recovery Secure Profile: Add Delivery Method Page");
  },
  mounted() {
    this.setCommonErrors();
    this.changeContextBiocatch("rsp_add_delivery_method_page");
    /* Event Listener For Closing Select Box */
    document.addEventListener("click", (e) => {
      if (this.showDropdown && e.target.closest("#drpdn") === null) {
        this.showDropdown = false;
      }
    });
    this.$nextTick(() => this.$refs.dropdownRef.focus());
    //this.updateUrlOnMount(window.location.href);
  },
  methods: {
    ...mapActions("profile", [
      "sendotpToDevice",
      "updateGotoComponent",
      "updateGotoOtpOvw",
      "updateGotoOtp",
      "getOtpDeviceList",
      "displayLoginIntercept",
    ]),
    ...mapActions("login", ["updateUnlockMyAccountAlerts"]),
    ...mapMutations("login", [
      "setDeliveryComponentName",
      "setLoader",
      "setCommonErrors",
    ]),
    ...mapMutations("profile", [
      "setUserInputText",
      "setSelectedOtpType",
      "setSkipOption",
    ]),
    closeModal() {
      $("#noAccessToOtpModal").modal("hide");
    },
    cancelRecoveryFlow() {
      // this.updateGotoOtp(false);
      // this.updateGotoOtpOvw(true);
      this.setDeliveryComponentName("NewVerifyDelivery");
      this.setSkipOption(false);
      this.updateUnlockMyAccountAlerts([]);
      this.setCommonErrors();
    },
    openModel() {
      $("#noAccessToOtpModal").modal({
        backdrop: "static",
        keyboard: false,
      });
    },
    cancelVerifyOtp() {
      this.resetShowVerifyOtp(false);
      this.updateUnlockMyAccountAlerts([]);
      this.setCommonErrors();
    },
    async onSubmit() {
      this.setLoader(true);
      try {
        const payload = {
          uuID: this.uuID,
        };
        if (this.selectedOtpType == "email") {
          payload.emailAddress = this.userInputText;
          payload.otpType = "email";
        } else if (this.selectedOtpType == "call") {
          payload.mtn = this.userInputText.replace(/-/g, "");
          payload.countryCode = "1";
          payload.otpType = "ivr";
        } else if (this.selectedOtpType == "text") {
          payload.mtn = this.userInputText.replace(/-/g, "");
          payload.countryCode = "1";
          payload.otpType = "sms";
        }

        // submit addotpdevice request
        await this.sendotpToDevice(payload);
        this.setLoader(false);
      } catch (error) {
        this.setLoader(false);
      }
    },

    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    deviceOptionSelected(selDevice) {
      this.selectedOtpMethod = selDevice;
      this.selectedOtpType = selDevice.id;
      this.userInputText = "";
      this.isValidText = false;

      this.$nextTick(() => this.$refs.userInputTextRef.focus());
    },
    deviceListkeyupHandler(e, index) {
      // logic to move up and down in device list with up & down arrow
      const deviceList = this.$refs.selectDeviceList;
      switch (e.key) {
        case "ArrowUp":
          if (index > 0) {
            deviceList[index - 1].focus();
          }
          if (index <= 0) {
            deviceList[deviceList.length - 1].focus();
          }
          break;
        case "ArrowDown":
          if (index < deviceList.length - 1) {
            deviceList[index + 1].focus();
          } else {
            deviceList[0].focus();
          }
          break;
        default:
          break;
      }
    },
    acceptMobileNumber(payload) {
      let x = payload.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      let phoneNum = !x[2]
        ? x[1]
        : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "");

      return phoneNum;
    },
    validateUserInput() {
      this.isValidText = false;
      if (this.selectedOtpType === "email") {
        if (!this.emailFormatRegex.test(this.userInputText)) {
          return;
        }
        this.isValidText = true;
      } else {
        this.userInputText = this.acceptMobileNumber(this.userInputText);
        if (this.userInputText.length === 12) {
          this.isValidText = true;
          return;
        }
      }
    },
  },
  computed: {
    ...mapGetters("login", ["redirectToSecProfilePage", "submitLoader"]),
    ...mapGetters("profile", [
      "uuID",
      "maskedValue",
      "showVerifyOTP",
      "otpDeviceList",
      "getShowMTNDevice",
    ]),
    formData: {
      get: function () {
        return this.$store.getters["login/formData"];
      },
      set: function (newVal) {
        this.$store.dispatch("login/updateFormData", newVal);
      },
    },
    disableDeliveryBtn() {
      if (this.selectedOtpType && this.isValidText) {
        return false;
      }

      return true;
    },
    getWarningMessage() {
      if (this.selectedOtpType === "email") {
        return translations.en.message["reset_security_invalid_email_err"];
      } else {
        return translations.en.message["reset_security_invalid_phone_err"];
      }
    },
  },
  watch: {
    redirectToSecProfilePage: function (newVal) {
      if (newVal) {
        window.location.href = newVal;
      }
    },
    selectedOtpType: function (newVal) {
      this.setSelectedOtpType(newVal);
    },
    userInputText: function (newVal) {
      this.setUserInputText(newVal);
    },
    showVerifyOTP: function (newVal) {
      if (newVal) this.setDeliveryComponentName("NewVerifyOTPComponent");
    },
  },
};
</script>
<style scoped>
.dropDefaultLabel {
  padding-right: 0px;
  padding-left: 0;
  flex: 1;
}
label {
  display: block !important;
}
.row.dropdown.dropdown-toggle {
  align-items: center;
}
.user-otp-input-container {
  margin-top: 32px;
}
.verify-input-container {
  margin-top: 32px;
}

.resend-passcode-link {
  margin-top: 16px !important;
}
.cant-receive-passcode-link {
  margin-top: 24px !important;
}
.dropdown-menu > li > a {
  color: #000 !important;
  text-decoration: none !important;
}
.vbg-rsp-add-btn {
  height: 44px !important;
  line-height: 18px;
  font-size: 16px;
  padding: 12px;
}
.btn-verify {
  height: 44px !important;
  line-height: 20px;
  font-size: 16px;
  padding: 12px;
}
.vbg-rsp-warning-msg {
  height: auto !important;
  margin-top: 8px !important;
  font-family: "VzNHGeTX-reg11" !important;
}
.vbg-rsp-link {
  font-size: 16px !important;
}
.vz-text-link {
  color: #000;
  margin-bottom: 10px !important;
  text-decoration: none !important;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
}
.btn:active,
.btn:hover,
.btn:focus {
  line-height: 18px;
  padding: 12px;
  font-size: 16px;
}
.padleft10 {
  padding-left: 12px !important;
}
.dropdown-menu > li > a {
  background: none !important;
  /* padding-left: 8px; */
}
.vbg-ff-input-label {
  font-family: "VzNHGeTX-reg11" !important;
}

@media (min-width: 1025px) {
  .vbg-rsp-add-btn {
    width: 220px !important;
  }
  .btn-verify {
    width: 120px;
  }
}
@media (max-width: 1024px) {
  .dropdown-menu li.chosen > a {
    color: #000 !important;
  }
  .dropdown-menu li.chosen {
    border-left: none !important;
  }
  .dropdown-menu > li > a {
    text-decoration: none !important;
    font-weight: bold;
    color: #747676 !important;
    letter-spacing: 0;
    line-height: 16px;
    font-size: 16px;
    margin-left: 0px;
    /* padding-left: 12px !important; */
  }
  .dropdown-menu li.chosen a {
    /* padding-left: 12px !important; */
  }
  .resend-passcode-link {
    margin-top: 24px !important;
  }
  .vz-text-link {
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
  }
}
@media (max-width: 768px) {
  .vbg-sp-btn-continue {
    display: flex;
    flex-direction: column-reverse;
  }
}

.modal-button {
  margin-top: 37.34px;
  height: 44px;
  width: 188px;
}
.modal-content-container {
  position: relative;
  width: 640px;
  height: 431px !important;
  margin: 0 auto;
  padding: 48px !important;
}
.modal-content-title {
  height: auto;
  width: 100%;
  font-size: 32px;
  line-height: 32px;
  margin-top: 0px !important;
}
.modal-content-description {
  margin-top: 24px;
  height: 40px;
  width: 100%;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
}
.close-icon-container {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 14px;
}
.close-icon-container:focus {
  outline: 1px dashed #000;
  outline-offset: -12;
}
.bttn-container {
  height: 44px;
  width: 190px;
  border-radius: 22px;
  background-color: #000000;
  font-size: 16px !important;
  line-height: 18px !important ;
  padding: 12px !important;
  text-align: center;
}
.modal-open {
  padding-right: unset !important;
}
.window-content {
  height: 96px;
  width: 90%;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .modal-content {
    height: auto;
  }
}

@media (max-width: 640px) {
  .modal-content-container {
    width: 100%;
    height: 100% !important;
    padding: 0px !important;
  }
  .modal-container {
    height: 360px;
    padding: 69px 0px 0px 16px;
  }
  .modal-content-title {
    height: 58px !important;
    font-size: 24px;
    line-height: 24px;
    margin-top: 10px;
  }
  .modal-content-description {
    margin-top: 24px;
  }
  .modal-loader {
    margin-top: 62px;
  }
  .modal-btn-container {
    padding-right: 16px;
  }
  .close-icon-container {
    height: 48px;
    width: 48px;
    position: relative;
    float: right;
  }
  .close-icon-container {
    top: -12px;
  }
  .bttn-container {
    width: 100%;
  }
  .window-content {
    height: 142px;
    width: 90% !important;
  }
}
@media (max-width: 280px) {
  .modal-content-description {
    max-width: 260px;
  }
  .modal-content-title {
    height: auto !important;
  }
  .mar-bottom50 {
    margin-bottom: 60px;
  }
}
@media (min-width: 641px) {
  .vbg-rel-bottom-40 {
    position: absolute;
    bottom: 40px !important;
  }
}
/* additional css */
.pwdles_main_heading {
  margin-bottom: 12px;
  /* margin-top: 32px; */
}
.pwdles_sub_heading {
  margin-bottom: 32px;
}
.dropdown {
  height: 44px !important;
  border: 1px solid #6f7171 !important;
}
.dropdown:focus {
  border: 1px solid #000000 !important;
}
.padleft10 {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 20px;
}
.del_item_label {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 18px;
}
.dropdown-menu {
  background: linear-gradient(
    180deg,
    rgba(243, 243, 245, 1) 0%,
    rgba(242, 242, 242, 1) 100%
  );
  border: none !important;
  border-radius: 7px;
  box-shadow: none;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-top: 1px !important;
}
.dropdown {
  border-radius: 4px;
}
.dropdown-menu li {
  border: none !important;
}
.verify_method {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  font-family: "VzNHGeDS-bold11";
  margin-bottom: 12px;
}
.verify_method_sub_text {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  font-family: "VzNHGeDS-reg11";
  letter-spacing: 0.5px;
}
.passcode_label {
  font-family: "VzNHGeTX-reg11";
  font-size: 12px !important;
  line-height: 16px;
  font-weight: 400;
}
.input_inine_err {
  margin-bottom: 8px !important;
}
.vbg-sp-btn-continue {
  margin-top: 32px !important;
}
.cant-receive-passcode-link {
  margin-top: 16px !important;
}
.pwd_cancel_btn {
  width: 100%;
  height: 44px;
}
@media only screen and (min-width: 1024px) {
  .pwd_cancel_btn {
    /* width: 150px; */
  }
}
.new_pwdless_input_field {
  margin-bottom: 0px;
}
.submit_btn_spacing {
  margin-top: 0px;
}
.dropdown-menu > li > a, .dropdown-menu > li > a:hover {
    padding: 12px 24px !important;
}
.pwd_less_btn_container{
  display: flex !important;
  flex-direction: column !important;
}
.new_pwdless_btn_section_mobile{
  margin-top: 12px;
  width: 100%;
}
</style>
