<template>
  <div>
    <h1 class="new_pwdless_main_heading pwdles_main_heading">
      Answer your secret question
    </h1>
    <form @submit.prevent.enter="validateAnswer(false)">
      <div class="new_pwdless_sqa__input_label">
        {{ selectedQuestion.question }}
      </div>
      <div class="input_container strength_label">
        <input
          class="new_pwdless_input_field sqa_input"
          ref="sqaRef"
          id="sqa"
          name="sqa"
          :type="isShow ? 'text' : 'password'"
          v-bind:class="[
            hideOtpValidateComponent
              ? ''
              : 'vbg-rsp-disabled-label border-bottom-disable',
            currentStep === 'SQA_INVALIDATED' ? 'vbg-input-warning' : '',
            sqaValidated && hideOtpValidateComponent ? 'vbg-input-sucess' : '',
          ]"
          v-model="selectedQuestion.answer"
          :disabled="isDisabled"
          :tabindex="devices.length + 4"
        />
        <span
          v-if="selectedQuestion.answer"
          class="toggle_text new_pwdless_remember_me_text"
          @click="isShow = !isShow"
          >{{ isShow ? "Hide" : "Show" }}</span
        >
        <span id="sqaErrorMsg" class="error_msg_style"> </span>
      </div>
      <div class="new_pwdless_btn_section">
        <button
          class="new_pwdless_submit_btn"
          type="submit"
          :class="isDisabledClass()"
          :disabled="continueDisabled"
        >
          Continue
        </button>
      </div>
    </form>
    <div v-if="showMTN">
      <div class="bar_contents">
        <div class="horizontal_bar"></div>
        <div class="middle_text">Or</div>
        <div class="horizontal_bar"></div>
      </div>
      <div class="forgot_label">Forgot the answer?</div>
      <div class="forgot_method">
        Use one of the methods below to skip this step.
      </div>
      <!-- <div>Data charges may apply for non-Verizon numbers.</div> -->
      <NewOtpDelivery showMtn="showMtn"></NewOtpDelivery>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { mapWaitingActions } from "vue-wait";
import translations from "@/common/assets/json/messages1.json";
import { setPageDetails } from "../../../../../public/adobeTagging";
import commonMixin from "@/common/mixin/commonMixins";
import commonErrorMixin from "../common/common-error-mixin";
import warningIcon from "@/common/assets/styles/corp/images/warning.png";
import checkIcon from "@/common/assets/styles/corp/images/checkmark.png";
import NewOtpDelivery from "../newStepUp/NewOtpDelivery.vue";
export default {
  name: "NewSqaComponent",
  components: {
    NewOtpDelivery,
  },
  async created() {
    setPageDetails("Forgot Password:SAQ");
  },
  mixins: [commonMixin, commonErrorMixin],
  data() {
    return {
      hideSubmit: false,
      content: translations.en.message["login_device_not_recognized_tooltip"],
      url:
        iamvar_cdnUrl +
        iamvar_imgSourceContext +
        "/business/login/includes/circle-loaderWHITE_48x48.gif",
      content3:
        translations.en.message["login_verify_and_dont_register_tooltip"],
      sqaValidationInputClass: "",
      sessionCookie: "",
      challengeMethod: "sqa",
      displayDiffLoginOption: false,
      whichRealm: true,
      isShow: false,
      warningIcon: warningIcon,
      checkIcon: checkIcon,
      showMTN: false,
    };
  },
  async mounted() {
    this.setChangedSelectedOption(true);
    this.changeContextBiocatch("forgot_password_sqa_page");
    if (this.urlOnMount != null || this.urlOnMount != undefined) {
      const hostUrl = this.urlOnMount;
      if (hostUrl.includes("/account/business/login/mobile")) {
        this.whichRealm = false;
      }
    }
    if (document.cookie.indexOf("X-VZ-C_APP") !== -1) {
      let iamvar_appCookie = getCookie("X-VZ-C_APP");
      if (iamvar_appCookie == "MVB") {
        this.whichRealm = false;
      }
    }

    // check if /verify is present on url
    var currentUrl = window.location.pathname;
    if (currentUrl.includes("/verify/")) {
      // update flowtype to POST_AUTH_FLOW
      this.updateFlowType("POST_AUTH_FLOW");
      // pass challengeMethod as part of the payload
      this.initializeStepup({ challengeMethod: "SQA" });
    } else {
      // no '/verify/' present
      console.log("getting user questions on pre-auth stepup");
      try {
        if (!this.selectedQuestion.question) {
          await this.getUserQuestions();
        }
      } catch (e) {
        console.log("Error");
      } finally {
        // this will run and set focus to
        // input field when user land on this page
        this.$nextTick(() => {
          this.$refs.sqaRef.focus();
        });
      }
    }

    if (this.$route.query.continueURL) {
      this.continueUrl = this.$route.query.continueURL;
    }

    // If coming from ODI, display IDM Message. Option for "login as different user" which redirects to login
    if (this.urlOnMount && this.urlOnMount.includes("appview=odi")) {
      this.displayDiffLoginOption = true;
    }
    this.selectedQuestion.answer = "";
    this.showMTN = false;
    this.updateFFSQAEnabled(false);
    const inputField = document.getElementById("sqa");
    const errorMessage = document.getElementById("sqaErrorMsg");

    inputField.addEventListener("input", () => {
      if (inputField.value === "") {
        errorMessage.textContent = "Please enter an answer to your secret question.";
        inputField.classList.add("error_input"); // Optional: Add a CSS class for styling
      } else {
        errorMessage.textContent = "";
        inputField.classList.remove("error_input"); // Optional: Remove the error class
      }
    });
  },
  methods: {
    ...mapActions("cvsstepup", [
      "validateSqa",
      "cancelVerification",
      "initializeStepup",
      "updateFlowType",
      "updateTriggerSendOTP",
      "updateSQAValidated",
      "sendOtpToDevice",
      "updateFFSQAEnabled",
    ]),
    ...mapActions("login", ["redirectToOpenam"]),
    ...mapMutations("login", ["setNavHistory"]),
    ...mapMutations("cvsstepup", ["setChangedSelectedOption", "setShowMtnDeliveryMethod"]),
    ...mapWaitingActions("cvsstepup", {
      getUserQuestions: "sqa_fetch_to_complete",
    }),
    deleteAlert(alert) {
      this.sqaAlerts.splice(this.sqaAlerts.indexOf(alert), 1);
    },
    getMsgIcon(index) {
      // return iamvar_cdnUrl+'account/business/login/includes/img/info.png'
      return "@/common/assets/styles/corp/images/" + index.type + ".png";
    },
    messageImage(index) {
      if (
        index.type == "error" ||
        index.type == "warning" ||
        index.type == "list"
      ) {
        return "newErrorMessageImage";
      } else {
        return index.type + "MessageImage";
      }
    },
    getCloseIcon() {
      return (
        iamvar_cdnUrl +
        iamvar_imgSourceContext +
        "/business/login/includes/img/close_white.png"
      );
    },
    getAlertType(index) {
      //when error occurs take the user to the top screen where the orange banner is shown
      window.scrollTo(0, 0);
      return index.type;
    },
    async validateAnswer(saveFingerprint) {
      this.clearAllErrors();
      if (this.disableSqaFields || !this.selectedQuestion.answer) {
        // the continue button is disabled and should not be clickable
      } else {
        // if(!this.sqaValidated) {
        let self = this;
        var sqaValidateParams = {
          sqa: self.selectedQuestion,
          saveFingerprint: saveFingerprint,
          sessionCookie: self.sessionCookie,
          challengeMethod: self.challengeMethod,
        };
        await this.validateSqa(
          sqaValidateParams,
          this.sessionCookie,
          this.challengeMethod
        );
        // this.updateTriggerSendOTP(true)
        // } else {
        //   this.updateTriggerSendOTP(true)
        // }
      }
    },
    switchToOTP() {
      this.$router.push({ path: "otp" });
    },
    redirectToLogin() {
      window.location.href = this.urlOnMount;
    },
    getOAuthClass(className = "oauth-flow", subClass = "") {
      return className + " " + subClass;
    },
    isDisabledClass() {
      if (
        this.disableSqaFields ||
        !this.selectedQuestion.answer ||
        this.submitLoader ||
        !this.hideOtpValidateComponent
      ) {
        return " vbg-ff-disabled";
      } else {
        return "";
      }
    },
  },
  computed: {
    ...mapGetters("cvsstepup", [
      "fingerprintSaved",
      "submitLoader",
      "isPwReset",
      "dynamicErrorClasses",
      "userQuestions",
      "selectedQuestion",
      "sqaSubmitLinkClass",
      "flowInitialized",
      "disableSqaFields",
      "sqaPageMessages",
      "currentStep",
      "flowType",
      "stepupReason",
      "otpEnabled",
      "urlOnMount",
      "sqaValidated",
      "hideOtpValidateComponent",
      "devices",
      "selectedOtpDevice",
    ]),
    vzigInstanceCreated: {
      get: function () {
        return this.$store.getters["cvsstepup/vzigInstance"];
      },
    },
    sqaAlerts: {
      get: function () {
        return this.$store.getters["cvsstepup/sqaAlerts"];
      },
      set: function (newVal) {
        this.$store.commit("cvsstepup/setSQAAlert", newVal);
      },
    },
    continueUrl: {
      get: function () {
        return this.$store.getters["cvsstepup/continueUrl"];
      },
      set: function (newVal) {
        this.$store.commit("cvsstepup/setContinueUrl", newVal);
      },
    },
    continueDisabled() {
      return (
        this.disableSqaFields ||
        !this.selectedQuestion.answer ||
        this.submitLoader
      );
    },
    isDisabled() {
      return this.disableSqaFields || !this.hideOtpValidateComponent;
    },
  },
  watch: {
    currentStep: async function (newVal) {
      if (newVal === "TOKEN_EXPIRED") {
        // this.cancelVerification()
        // window.location.href = this.urlOnMount
      } else if (newVal === "SQA_VALIDATED") {
        if (this.continueUrl) {
          window.location.href = this.continueUrl;
          // } else if(this.flowType === 'forgot_password') {
          // this.cancelVerification()
          // this.initializeStepup({ challengeMethod: 'OTP' })
        } else if (this.flowType === "auth") {
          this.redirectToOpenam();
        }
        // } else if(this.flowType === 'forgot_password' && newVal === 'FLOW_INITIALIZED') {
        // this.$router.push({ name: 'FFOtpComponent'}).catch(err => {throw err})
      } else if (newVal == "SQA_INVALIDATED") {
        var deviceType = JSON.stringify(this.devices)
        if((deviceType.includes("SMARTPHONE") || deviceType.includes("BASIC"))){
          // await this.cancelVerification()
          // await this.initializeStepup();
          this.setShowMtnDeliveryMethod(true)
          this.showMTN = true;
        }
        this.selectedQuestion.answer = "";
      }
    },
    vzigInstanceCreated: function (newVal) {
      // if(newVal != null) {
      //   if(!this.selectedQuestion.question){
      //   console.log('calling getuserquestions from watch')
      //   this.getUserQuestions()
      //   }
      // }
    },
    isShow: function (newVal) {
      if (newVal) {
        setTimeout(() => {
          this.isShow = false;
        }, 10000);
      }
    },
    "selectedQuestion.answer": function () {
      if (this.sqaValidated) {
        this.updateSQAValidated(false);
      }
    },
  },
};
</script>

<style scoped>
.forgot_label {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  font-weight: 700;
  font-family: "VzNHGeDS-bold11";
}
.forgot_method {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  font-weight: 400;
  font-family: "VzNHGeDS-reg11";
  color: #000000;
  margin-bottom: 32px;
}
.new_pwdless_btn_section {
  margin: 32px 0px;
}
.bar_contents {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 32px 0px;
}
.horizontal_bar {
  flex: 1;
  width: 40%;
  background-color: #d8dada;
  height: 1px;
}
.middle_text {
  margin: 0px 8px;
  color: #000000;
  font-family: "VzNHGeDS-bold11";
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}
.pwdless_pass_forgot_text,
.back_icon_div {
  margin-bottom: 24px;
}
.pwdles_pass_sub_heading {
  margin-bottom: 4px;
}
.pwdless_pass_forgot_text {
  text-align: right;
}
.input_container {
  position: relative;
}
.toggle_text {
  position: absolute;
  right: 12px;
  top: 11.5px;
  border-bottom: 1px solid #000000;
  padding-bottom: 2px;
  cursor: pointer;
}
.pwdles_pass_main_heading {
  margin-bottom: 24px;
}
.pwdless_pass_user_gap {
  margin-bottom: 32px;
}
.pwdles_main_heading {
  margin-bottom: 24px;
}
.pwdles_sub_resend_text {
  margin-top: 64px;
}
.sqa_input {
  margin-bottom: 0;
}
.error_input {
  border: 1px solid #B95319 !important;
  background-color: #FFECE0;
}
.error_msg_style {
  font-size: 14px;
  font-weight: bold;
}
</style>
