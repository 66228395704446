<template>
  <div class="vbg-ff-root-container">
    <Header v-if="this.getWhichRealm"></Header>
    <div class="new_pwdless_parent_container">
      <BlockUI
        v-if="
          submitLoader || getTransactionLoader || pollingLoader || forgotLoader
        "
        :url="url"
      ></BlockUI>
      <div class="new_pwdless_form_container" v-if="getNavHistory.length">
        <div class="new_pwdless_box_container">
          <div class="new_pwdless_box">
            <div class="new_pwdless_content">
              <ErrorMessage
                :message_details="getCommonErrors[0]"
                v-if="getCommonErrors.length"
              />
              <NavigationHistory
                @redirectToLogin="redirectToLogin"
                v-if="getCurrentComponent.name != 'NewAMResetPasswordComponent'"
              />
              <!-- Loading Required components based on route changes -->
              <component
                :is="getCurrentComponent.name"
                :ref="getCurrentComponent.name"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer v-if="this.getWhichRealm"></Footer>
    <div></div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Header from "@/vbgauth/components/LegalHeader";
import Footer from "@/vbgauth/components/ForgotFlowFooter";
import NavigationHistory from "../common/NavigationHistory.vue";
import ErrorMessage from "../common/ErrorMessage.vue";
import NewAMForgotUsrnmComponent from "./NewAMForgotUsrnmComponent";
import NewAMForgotPwdComponent from "./NewAMForgotPwdComponent";
import NewAMResetPasswordComponent from "./NewAMResetPasswordComponent";
import NewAMForgotUserIDInfo from "./NewAMForgotUserIDInfo.vue";
import CommonErrorMixin from "../common/common-error-mixin";
export default {
  name: "NewAMForgotInfoRootComponent",
  components: {
    Header,
    Footer,
    NavigationHistory,
    ErrorMessage,
    NewAMForgotUsrnmComponent,
    NewAMForgotPwdComponent,
    NewAMResetPasswordComponent,
    NewAMForgotUserIDInfo,
  },
  mixins: [CommonErrorMixin],
  mounted() {
    // Checking user coming from desktop or chatbot to count the metrics
    if (
      login_assistant_unlockaccount_metrics &&
      (this.$route.query?.loginAssistantUid ||
        this.$route.query?.loginAssistantForgotPassword ||
        this.$route.query?.loginAssistantForgotUserId)
    ) {
      this.setLoginChatbot(true);
    } else this.setLoginChatbot(false);
    let appUrl = this.$route.query.appUrl;
    let gotoUrl = this.$route.query.goto;

    if (this.urlOnMount != null || this.urlOnMount != undefined) {
      const hostUrl = this.urlOnMount;
      if (hostUrl.includes("/account/business/login/mobile")) {
        this.whichRealm = false;
      }
    }
    if (document.cookie.indexOf("iamvar_realm") !== -1) {
      var iamvar_realmCookie = getCookie("iamvar_realm");
      if (iamvar_realmCookie == "MOBILE") {
        this.whichRealm = false;
      }
    }
    if (document.cookie.indexOf("X-VZ-C_APP") !== -1) {
      let iamvar_appCookie = getCookie("X-VZ-C_APP");
      if (iamvar_appCookie == "MVB") {
        this.whichRealm = false;
      }
    }

    if (appUrl != undefined) {
      if (gotoUrl != undefined) {
        // encode gotoUrl before appending it to urlOnMount
        gotoUrl = encodeURIComponent(gotoUrl);
        this.$store.dispatch(
          "cvsstepup/updateUrlOnMount",
          appUrl + "?goto=" + gotoUrl
        );
      } else this.$store.dispatch("cvsstepup/updateUrlOnMount", appUrl);
    }

    if (gotoUrl != undefined) {
      this.$store.dispatch("cvsstepup/updateGotoUrl");
    }
    window.onpopstate = function () {
      window.history.forward();
    };
  },
  data() {
    return {
      whichRealm: true,
      url:
        iamvar_cdnUrl +
        iamvar_imgSourceContext +
        "/business/login/includes/circle-loaderWHITE_48x48.gif",
    };
  },
  methods: {
    // ...mapActions('cvsstepup' [
    //   'updateUrlOnMount'
    // ]),
    // ...mapActions('login', [
    //   'updateGotoUrl'
    // ])
  },
  computed: {
    ...mapGetters("cvsstepup", ["flowType", "urlOnMount"]),
  },
};
</script>
<style scoped>
/* .forgot-flow-container {
    min-height: 95vh;
  }
  @media (max-width: 767px) {
    .forgot-flow-container {
      min-height: 80vh;
    }
  }
  #tsd-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  } */
</style>
