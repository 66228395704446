<template>
    <div class="login-container">
        <BlockUI v-if="submitLoader" :url="url"></BlockUI>
        <h2 v-if="this.gotoPage" id="sign-in-title"> Sign In </h2>
        <h2 v-else id="sign-in-title"> Please sign into My Business </h2> <br /> <br /> <br />

        <!-- <span class="vz-error-msg" id="errorMsgVZW" v-if="authError" style="margin-right: 50%;">{{authError.errorMessage}}</span> -->
        <div class="messagesInfo" v-bind:class="getAlertType" v-if="authError" style="margin-right: 50%;">
            <p v-if="getAlertType === 'success' || getAlertType === 'information'" v-html="authError.errorMessage" />
            <p v-else-if="authError.errorCode === 'B360_ENROLLED_PENDING_REGISTRATION' || authError.errorCode === 'PROSPECT_ENROLLED_PENDING_REGISTRATION' || authError.errorCode === 'ACCOUNT_ALREADY_UNLOCKED' || authError.errorCode === 'ACCOUNT_UNLOCK_ALREADY_REQUESTED'" v-html="authError.errorMessage"></p>
            <p v-else-if="authError.errorCode === 'USER_MIGRATED'">
            <span>{{authError.errorMessage }} <a :href="authError.fedlink" style="color: black">{{ authError.fedcontent}}</a>
            </span>
             </p>
            <p v-else> {{ authError.errorMessage }} 
                <a v-bind:href="this.idmRedirect" v-if="this.showClickHereMsg" style="color: white; text-decoration: underline;"> click here </a> 
            </p> 
        </div>
        <div class="messagesInfo" v-bind:class="getAlertType" v-if="!authError && cvsAuthAlert" style="margin-right: 50%;">
            <p v-html="cvsAuthAlert.errorMessage"></p>
        </div>
        <br />

        <form @submit.prevent="submit" ref="loginForm">
            <div>
                <label class="form-label">Username</label> <br />
                <input type="text" v-model="formData[0].input[0].value" id="simplelogin_username" name="simplelogin_username"> <br />

                <label class="form-label"> Password </label> <br />
                <input type="password" v-model="formData[1].input[0].value" id="simplelogin_password" name="simplelogin_password"> <br />
            </div>

            <div v-if="this.jCaptchaFlag" style="display: block;">
                <img id="captchaImg" :src="getCaptchaImgUrl()"/> <br />
                <button class="refreshCaptcha" v-on:click="refreshCaptchaImgUrl()">Refresh Image</button> <br /> <br />

                <label class="vz-input-label" for="captchaInput">Type the characters from the above image</label> <br />
                <input type="text" style="display: block;" id="captchaInput" class="form-control vz-input-field" v-model="captchaText" /> <br />
            </div>
            
            <div v-if="this.gotoPage" style="display: block;">
                <label style="padding: 0 5px 0 5px;"> Remember Me </label>
                <input type="checkbox" id="simplelogin_remember_me" name="simplelogin_remember_me" v-model="rememberMe"> <br />
            </div>
            
            <div>
                <InvisibleRecaptcha :sitekey="loginCaptchaKey" :disabled="!isFormComplete" v-if="this.captchaOn" id="captcha-submit" theme="light" badge="bottomright" :type="submit" :callback="submitLoginForm"> Sign In </InvisibleRecaptcha>
                <input type="submit" v-if="!this.captchaOn" value="Sign In" :disabled="!isFormComplete" @click="submitLoginForm()" style="width: 15%;" id="simplelogin_signin_button" name="simplelogin_signin_button">
            </div>
        </form>
        <br />

        <div v-if="this.gotoPage">
           <span class="black-ink-link" style=" cursor: pointer" @click=" FrUsername(); mybizzForget(false); " id="simplelogin_forgot_username" name="simplelogin_forgot_username"> Forgot Username </span>
            <a class="black-ink-link" style="text-decoration: none; cursor: default;"> or </a>
            <span class="black-ink-link" style=" cursor: pointer" @click=" FrPassword(); mybizzForget(false); " id="simplelogin_forgot_password" name="simplelogin_forgot_password"> password </span>
            <br />
        </div>
        <div id="register-div">
            <a class="black-ink-link" v-bind:class="displaySignUpFalse" v-bind:href="SignUpUrl"> Sign up for an account </a>
        </div>
    </div>
</template>

<script>
import InvisibleRecaptcha from 'vue-invisible-recaptcha';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import Header from '@/common/components/Header';
import Footer from '@/common/components/Footer';
import {setPageDetails} from '../../../../public/adobeTagging';
import commonMixin from '@/common/mixin/commonMixins';
import loadingImage from "@/common/assets/styles/corp/images/circle-loaderWHITE_48x48.gif";
import translations from "@/common/assets/json/messages1.json";
import CommonErrorMixin from "@/vbgauth/components/VbgLoginNew/common/common-error-mixin";

export default {
    name: 'TSDLoginComponent',
    components: { Header, Footer, InvisibleRecaptcha },
    mixins: [commonMixin, CommonErrorMixin],
    data() {
        return {
            //url: iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/img/circle-loaderWHITE_48x48.d05e483d.gif',
            //url : loadingImage,
            url: iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/circle-loaderWHITE_48x48.gif',
            userId: '',
            userPassword: '',
            rememberMe: false,
            canSubmit: false,
            captchaOn: true,
            SignUpUrl:'',
            showClickHereMsg: false,
            idmRedirect: '',
            displaySignUpFalse: '',
            gotoPage: true,
            jCaptchaFlag: false,
            captchaText: null,
            downlinkSpeed: null,
            isSlowConnection: false,
        }
    },
    async created() {
        setPageDetails('Login:landing');
        this.getLoginCaptchaEnabledEnvVar();
        this.checkForCaptchaOffConditions();
    },
    mounted() {
        iamvar_pwdLessFlowType = 'basic'
        this.changeContextBiocatch('simple_login_page')
        iamvar_realm = 'LOGIN';
        // get gotourl from mixins
        this.$store.dispatch('login/updateGotoUrl', this.getGotoUrl());
        this.$store.dispatch('login/updateEncodedGotoUrl', this.getGotoUrl());
        this.removeExistingVBGCCookie();
        this.getSessionInfo();
        this.updateUrlOnMount(window.location.href);
        this.resetState();

        const hostUrl = window.location.href
        if(hostUrl.includes('/account/business/odasignin')) {
            this.gotoPage = false;
        }
        else if(hostUrl.includes('/account/business/ilogin')) {
            this.jCaptchaFlag = true;
        }

        // call rememberMe function only if uid query parameter is not present
        // let params = new URLSearchParams(window.location.search);
        if(!this.getParameterByName('uid')) {
            this.callRememberMe();
        }
        this.processQueryParameters();
        // console.log('idm url in tsd ', iamvar_idmUrl);             
        if(this.getParameter('appGroup')==='TSD' && this.getParameter('client_id')==='mdp_agent'){
            this.SignUpUrl=iamvar_mdpSignUpUrl         
        }
        else if(this.getParameter('appGroup')==='TSD' && this.getParameter('client_id')==='tsd_agent'){
            this.SignUpUrl=iamvar_thingSpaceSignUpUrl
        }
        else if(this.getParameter('appGroup')==='TSM' || this.getParameter('client_id')==='tss_agent_gz'){
             this.SignUpUrl=iamvar_ccSignUpUrl
        }
        else{
            this.displaySignUpFalse = 'vz-error'
        }
        if(this.getAlertType == 'success' || this.cvsAuthAlert) {
            this.updateRemoveMessage(true)
        }
        if(this.getParameterByName('success') =='true' || this.getParameterByName('success') == true) {
            var alertMsg = translations.en.message["forgot_username_display"]
           // var msg = [{msg: alertMsg.replace('$0', this.getParameterByName('uid')), type:'success'}]
            this.setAlertTypeUpdate('success')
            this.updateCvsAuthAlert({ errorMessage: alertMsg.replace('$0', this.getParameterByName('uid')) })
        }
        window.localStorage.setItem("InitialRoute", window.location.href);
        // Initial check for Internet speed
        
    },
    beforeDestroy(){
        this.formData[0].input[0].value = "";
        this.formData[1].input[0].value = "";
  },
    methods: {
        ...mapMutations('login', ['setPwdUsername', 'setRedirectToStepupPage', 'setAlertType', 'setAuthError']),
        ...mapActions('login', [
                'submitAMLoginForm',
                'callRememberMe',
                'removeExistingVBGCCookie',
                'getSessionInfo',
                'updateUrlOnMount',
                'resetState',
                'updateCaptchaToken',
                'updateFederalPortalIndicator',
                'mybizzForget',
                'setAuthErrorUpdate',
                'setAlertTypeUpdate'
            ]
        ),
        ...mapActions('cvsstepup', [ 'initializeStepup', 'updateUserIdFlow', 'updateCvsAuthAlert','updateRemoveMessage', 'updateUsernameForStepup' ]),
        submitLoginForm: function (recaptchaToken) {
            this.updateCvsAuthAlert(null)
            let payload = {
                formData: this.formData,
                rememberUser: this.rememberMe,
                captchaAuth: this.captchaText,
                additionalAuth:'VBG_LOGIN'
            };
            
            // update state with new captcha token
            if(this.captchaOn)
                this.updateCaptchaToken(recaptchaToken);

            this.submitAMLoginForm(payload);
            this.setPwdUsername(this.formData[0].input[0].value)
            this.updateUsernameForStepup(this.formData[0].input[0].value.trim());
            // might not be needed since submit button is disabled when form is empty
            /*if(this.$refs.loginForm.validate()) {
                this.submitAMLoginForm(payload);
            }*/

            // TODO: implement valid/invalid logic. For right now, redirect to OTP regardless of result
            // this.$router.push({ path: '/vbg-tsd-login/otp' });
        },
        getParameter(url) {
            let decodeUrl=decodeURIComponent(document.location)
            url = url.replace(/[\[\]]/g, '\\$&');
            var regex = new RegExp('[?&]' + url + '(=([^&#]*)|&|#|$)'),
                results = regex.exec(decodeUrl);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, ' '));
        },
        processQueryParameters() {
           // let params = new URLSearchParams(window.location.search);

            if(this.getParameterByName('uid')) {
                this.formData[0].input[0].value = this.getParameterByName('uid');
            }
            if(this.getParameterByName('rememberMe')) {
                if(this.getParameterByName('rememberMe') == 'true')
                    this.rememberMe = true;
            }
        },
        getLoginCaptchaEnabledEnvVar() {
            console.log('(Env based) captcha is on = ', iamvar_enableLoginCaptcha);
            this.captchaOn = iamvar_enableLoginCaptcha;
        },
        checkForCaptchaOffConditions() {
            // turn off captcha when instance is related to a federal portal
            let gotoParam = this.getParameterByName('goto');

            // if goto has 'oauth', get redirect_uri and check if redirect_uri has any of the federalPortalKeywords
            if(gotoParam != null && gotoParam.includes('oauth')) {

                // decode goto in order to easily access redirect_uri parameter
                let decodedGoto = decodeURIComponent(gotoParam);
                // let redirectUriParam = this.getParameterByName('redirect_uri');
                let decodedParameters = new URLSearchParams(decodedGoto);
                let redirectUriParam = decodedParameters.get('redirect_uri');

                if(iamvar_federalPortalKeyWords.some(str => redirectUriParam.includes(str))) {
                    this.captchaOn = false;
                    this.updateFederalPortalIndicator(true);
                }
            } else { // check if goto has any of the federalPortalKeywords
                //if(iamvar_federalPortalKeyWords.some(str => gotoParam.includes(str))) {
                //    this.captchaOn = false;
                //    this.updateFederalPortalIndicator(true);
                //}
            }
        },
        addRedirectUrlToErrorBanner() {
            /* if the current authError (banner showed on screen) is for the user being redirected to IDM 
               (errorCode = REDIRECT_TO_IDM_REQUIRED) we need to add text that says "click here", 
               with an href of the IDM url
            */

           // TODO: confirm if 'redirected' is a good key word to identify this error message
           let errorMsg = this.$store.getters['login/authError'];
           if(errorMsg.errorMessage.includes('redirected')) {
               this.showClickHereMsg = true;
           }

        },
        getHostName(url) {
            var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
            if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
                return match[2];
            }
            else {
                return null;
            }
        },
        getCaptchaImgUrl() {
            let captchaImgHref = this.getHostName(window.location.href);
            return this.sanitizeUrl("https://" + captchaImgHref + "/vzauth/vbg/captcha");
        },
        refreshCaptchaImgUrl() {
            let captchaImgHref = this.getHostName(window.location.href);
            document.getElementById("captchaImg").src = this.sanitizeUrl("https://" + captchaImgHref + "/vzauth/vbg/captcha?cb=" + Math.random());
        },
        FrUsername() {
            // this.$router.push({ path: "/login/recovery/forgotusername" });
            if(this.gotoUrl) {
                    this.$router.push({ path: '/login/recovery/forgotusername', query: {goto: this.gotoUrl}})
                } else {
                    this.$router.push({ path: '/login/recovery/forgotusername' })
                }
            this.updateUserIdFlow('BASIC')
        },
        FrPassword() {
            // this.$router.push({ path: "/login/recovery/forgotpassword" });
            // const gotoURL = this.getParameterByNameURL('goto', this.urlOnMount);
                if(this.gotoUrl) {
                     this.$router.push({ path: '/login/recovery/forgotpassword', query: {goto: this.gotoUrl}})
                        } else {
                    this.$router.push({ path: '/login/recovery/forgotpassword' })
                    }
        }
    },
    computed: {
        ...mapGetters('login', [
            'authError',
            'submitLoader',
            'iamvar_enableCaptcha',
            'redirectToStepupPage',
            'redirectToSecProfilePage',
            'iamvar_idmUrl',
            'loginCaptchaKey',
            'getAlertType',
            'idmRedirectUrl',
            'rememberMeTrue',
            'getUnifiedLoginAlerts',
            'remembermeUserName',
            'gotoUrl'
        ]),
        ...mapGetters('cvsstepup', [ 'currentStep', 'cvsAuthAlert' ]),
        ...mapGetters('forgotflow',[ "usrName" ]),
        isFormComplete() {
            let captchaComplete;
            if(this.jCaptchaFlag) {
                if (this.captchaText != null) {
                    captchaComplete = true;
                }
                else {
                    captchaComplete = false;
                }
            }
            else {
                captchaComplete = true;
            }
            // return this.userId && this.userPassword;
            return this.formData[0].input[0].value.length !== 0 &&
                   this.formData[1].input[0].value.length !== 0 &&
                   captchaComplete;
        },

        formData: {
            get: function () {
                return this.$store.getters['login/formData'];
            },
            set: function (newVal) {
                this.$store.dispatch('login/updateFormData', newVal);
            }
        },
        gotoUrl: {
            get: function () {
                return this.$store.getters['login/gotoUrl'];
            },
            set: function (newVal) {
                this.$store.dispatch('login/updateGotoUrl', newVal);
                this.$store.dispatch('login/updateEncodedGotoUrl', newVal);
            }
        },
    },
    watch: {
        redirectToStepupPage: function(newVal) {
            if(newVal) {
                this.initializeStepup();
                this.setRedirectToStepupPage(false)
            }
        },
        redirectToSecProfilePage: function(newVal) {
            if(newVal) {
                window.location.href = newVal;
            }
        },
        rememberMeTrue: function(newVal) {
            if(newVal == 'setToTrue') {
                this.rememberMe = true
            }
        },
        currentStep: function(newVal) {
            if(newVal === 'FLOW_INITIALIZED') {
                if(this.gotoUrl != null) {
                    pwd_less_new_login ? this.$router.push({name: "PwdLessOtpDelivery", query: { goto: this.gotoUrl }}) :
                    this.$router.push({ name: 'FFStepupRootComponent', query: { goto: this.gotoUrl } }); //.catch(err => {throw err});
                } else {
                    pwd_less_new_login ? this.$router.push({ path: '/login/stepup/otpdelivery'}) :
                    this.$router.push({ name: 'FFStepupRootComponent' });
                }
            }
        },
        idmRedirectUrl: function(newVal) {
            if(newVal)
                this.idmRedirect = newVal;
        },
        authError: function(newVal) {
            if(newVal) {
                this.addRedirectUrlToErrorBanner();
                if(this.jCaptchaFlag) {
                    this.refreshCaptchaImgUrl();
                    this.captchaText = null;
                }
            }
        },
        remembermeUserName: function(newval) {
          if(newval != "" || newval != null) {
            console.log("remembermeUserName=", newval)
            this.formData[0].input[0].value = newval
          }
        },
        cvsAuthAlert: function(newVal) {
            if(this.getAlertType == 'success' || newVal) {
                this.updateRemoveMessage(true)
            }
        },
        downlinkSpeed: function(newVal) {
          if(newVal < 1) {
            // this.isSlowConnection = true
            var infoMsg = translations.en.message["slow_internet_msg"]
            this.setAuthError({errorMessage: infoMsg })
            this.setAlertType('information')
            setTimeout(function () {
              commit('setAuthError', false)
            }, 10000)
          }
        },
    }
}

</script>

<style scoped>
.login-container
{
    margin: 5% 0 15% 25%;
}
input[type=text], input[type=password]
{
    border-color: #d8dada #d8dada #000000 #d8dada;
    border-radius: 0px;
    color: #000000;
    display: block;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    border: 1px solid #d8dada;
    background-color: #ffffff;
    background-image: none;
    height: 42px;
    width: 279px;
    box-sizing: border-box;
    border-bottom: 1px solid #000;
}
#sign-in-title
{
    height: 31px;
    width: 308px;
    color: #000000;
    font-size: 32px;
    letter-spacing: 0;
    line-height: 30.4px;
    font-weight: bold !important;
}
.form-label
{
    height: 12px;
    color: #747676;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 12px;
    font-weight: 400 !important;
}
input[type=submit]
{
    width: auto;
    min-width:120px;
    background-color: black;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 30px;
    cursor: pointer;
}
input[type=submit]:disabled
{
    width: auto;
    min-width: 120px;
    background-color: #d4d6d9;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 30px;
    cursor: auto;
}
input[type=checkbox]
{
    padding: 5px;
    background-color: aqua;
    position: static;
}
.black-ink-link
{
    width: 170px;
    color: #000000 !important;
    text-decoration: underline;
    margin-top: 10px;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16.8px;
}
.register-box
{
    float: right;
    background-color: dimgrey;
    margin-right: 15%;
}
.register-box span
{
    font-size: 10px;
}
.footer-banner {
    padding: 20px 50px 0px 50px;
    
}
#captcha-submit {
    width: auto;
    min-width:120px;
    background-color: black;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 30px;
    cursor: pointer;
}
#captcha-submit:disabled {
    width: auto;
    min-width: 120px;
    background-color: #d4d6d9;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 30px;
    cursor: auto;
}
#register-div {
    display: inline-block;
    margin-top: 20px;
}
.vz-error {
  display: none;
}

.refreshCaptcha{
  border: none;
  background: white;
  text-decoration: underline;
}

</style>
