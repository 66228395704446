var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',{staticClass:"new_pwdless_main_heading pwdles_main_heading"},[_vm._v(" Answer your secret question ")]),_c('form',{on:{"submit":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.validateAnswer(false)}}},[_c('div',{staticClass:"new_pwdless_sqa__input_label"},[_vm._v(" "+_vm._s(_vm.selectedQuestion.question)+" ")]),_c('div',{staticClass:"input_container strength_label"},[((_vm.isShow ? 'text' : 'password')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedQuestion.answer),expression:"selectedQuestion.answer"}],ref:"sqaRef",staticClass:"new_pwdless_input_field sqa_input",class:[
          _vm.hideOtpValidateComponent
            ? ''
            : 'vbg-rsp-disabled-label border-bottom-disable',
          _vm.currentStep === 'SQA_INVALIDATED' ? 'vbg-input-warning' : '',
          _vm.sqaValidated && _vm.hideOtpValidateComponent ? 'vbg-input-sucess' : '',
        ],attrs:{"id":"sqa","name":"sqa","disabled":_vm.isDisabled,"tabindex":_vm.devices.length + 4,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.selectedQuestion.answer)?_vm._i(_vm.selectedQuestion.answer,null)>-1:(_vm.selectedQuestion.answer)},on:{"change":function($event){var $$a=_vm.selectedQuestion.answer,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.selectedQuestion, "answer", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.selectedQuestion, "answer", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.selectedQuestion, "answer", $$c)}}}}):((_vm.isShow ? 'text' : 'password')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedQuestion.answer),expression:"selectedQuestion.answer"}],ref:"sqaRef",staticClass:"new_pwdless_input_field sqa_input",class:[
          _vm.hideOtpValidateComponent
            ? ''
            : 'vbg-rsp-disabled-label border-bottom-disable',
          _vm.currentStep === 'SQA_INVALIDATED' ? 'vbg-input-warning' : '',
          _vm.sqaValidated && _vm.hideOtpValidateComponent ? 'vbg-input-sucess' : '',
        ],attrs:{"id":"sqa","name":"sqa","disabled":_vm.isDisabled,"tabindex":_vm.devices.length + 4,"type":"radio"},domProps:{"checked":_vm._q(_vm.selectedQuestion.answer,null)},on:{"change":function($event){return _vm.$set(_vm.selectedQuestion, "answer", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedQuestion.answer),expression:"selectedQuestion.answer"}],ref:"sqaRef",staticClass:"new_pwdless_input_field sqa_input",class:[
          _vm.hideOtpValidateComponent
            ? ''
            : 'vbg-rsp-disabled-label border-bottom-disable',
          _vm.currentStep === 'SQA_INVALIDATED' ? 'vbg-input-warning' : '',
          _vm.sqaValidated && _vm.hideOtpValidateComponent ? 'vbg-input-sucess' : '',
        ],attrs:{"id":"sqa","name":"sqa","disabled":_vm.isDisabled,"tabindex":_vm.devices.length + 4,"type":_vm.isShow ? 'text' : 'password'},domProps:{"value":(_vm.selectedQuestion.answer)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.selectedQuestion, "answer", $event.target.value)}}}),(_vm.selectedQuestion.answer)?_c('span',{staticClass:"toggle_text new_pwdless_remember_me_text",on:{"click":function($event){_vm.isShow = !_vm.isShow}}},[_vm._v(_vm._s(_vm.isShow ? "Hide" : "Show"))]):_vm._e(),_c('span',{staticClass:"error_msg_style",attrs:{"id":"sqaErrorMsg"}})]),_c('div',{staticClass:"new_pwdless_btn_section"},[_c('button',{staticClass:"new_pwdless_submit_btn",class:_vm.isDisabledClass(),attrs:{"type":"submit","disabled":_vm.continueDisabled}},[_vm._v(" Continue ")])])]),(_vm.showMTN)?_c('div',[_vm._m(0),_c('div',{staticClass:"forgot_label"},[_vm._v("Forgot the answer?")]),_c('div',{staticClass:"forgot_method"},[_vm._v(" Use one of the methods below to skip this step. ")]),_c('NewOtpDelivery',{attrs:{"showMtn":"showMtn"}})],1):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bar_contents"},[_c('div',{staticClass:"horizontal_bar"}),_c('div',{staticClass:"middle_text"},[_vm._v("Or")]),_c('div',{staticClass:"horizontal_bar"})])
}]

export { render, staticRenderFns }