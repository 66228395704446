<template>
	<div>
		<BlockUI v-if="submitLoader" :url="url"></BlockUI>
		<h1 class="push-heading">Open the My Verizon For Business App</h1>
		<p class="push-sub-heading">Approve the request that was sent to your <span class="device-name">{{ deviceName
				}}</span> to log in.</p>
		<img class="pushimage"/>
		<div class="new_pwdless_remember_me">
			<input type="checkbox" class="new_pwdless_checkbox dark_bg_checkbox" v-model="defaultMethod" />
			<span class="new_pwdless_remember_me_text">
				Make this as my default log in method.
			</span>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import commonMixin from '@/common/mixin/commonMixins'
import translations from '@/common/assets/json/messages1.json'
import {
	setPageDetails,
	eventTracking,
	errorSet,
} from "../../../../../public/adobeTagging";
// import push_image from "@/common/assets/styles/corp/images/push.gif";

export default {
	name: 'NewPwdLessPushRegistered',
	mixins: [commonMixin],
	data() {
		return {
			pushimage: push_image,
			url: iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/circle-loaderWHITE_48x48.gif',
			pushImage1: iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/img/pushImage.gif',
		};
	},
	mounted() {
		this.changeContextBiocatch('pwdless_login_send_push_notification_page')

		if (this.pwdUsername == null) {
			this.$router.push({ name: 'PwdLessLoginRootComponent' })
		}
		if (this.getParameterByName("isPushRedirected") === 'true' || this.getParameterByName("isPushRedirected") === true) {
			this.submitPwdLessPushLogin({ userName: this.pwdUsername });
		}
		if (this.optionMethod == 'opt1') {
			this.defaultMethod = this.makeDefaultMethodTrue
		}
	},
	computed: {
		...mapGetters('login', ['gotoUrl', 'pwdUsername', 'submitLoader', 'optionMethod', 'makeDefaultMethodTrue','deviceName']),
		formData: {
      get: function () {
        return this.$store.getters['login/formData'];
      },
      set: function (newVal) {
        this.$store.dispatch('login/updateFormData', newVal);
      }
    },
		defaultMethod: {
			get: function () {
				return this.$store.getters['login/makeDefaultMethodTrue'];
			},
			set: function (newVal) {
				this.$store.dispatch('login/makeDefaultMethodTrue', newVal);
				if (newVal == true) {
					let usernameOption = this.pwdUsername + '|' + 'opt1'
					this.encryptDefaultMethod(usernameOption);
				} else {
					for (var i = 0; i < iamvar_vbgcRemCookieDomain.length; i++) {
						document.cookie = iamvar_makeDefaultMethodName + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=' + iamvar_vbgcRemCookieDomain[i] + ';path=' + iamvar_vbgcRemCookiePath + ';secure=' + iamvar_vbgcRemCookieSecure + ';comment=deleting from ui'
					}
				}
			}
		},
	},
	methods: {
		...mapActions("login", ["encryptDefaultMethod", "submitPwdLessPushLogin"]),

	},
	watch: {
		makeDefaultMethodTrue: function (newVal) {
			if (newVal) {
				this.defaultMethod = newVal
			}
		},
	}
};
</script>

<style scoped>
.push-heading {
	margin-bottom: 12px;
}

.push-sub-heading {
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.5px;
	margin-bottom: 48px;
}

.pushimage {
	display: grid;
	place-items: center;
	margin: 0 auto;
	width: 130px;
	height: 236.51px;
	gap: 0px;
	opacity: 0px;
	margin-bottom: 24px;
    background-image: url('@/common/assets/styles/corp/images/push.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.new_pwdless_remember_me {
	justify-content: normal;
}

.device-name {
	font-weight: bold;
}
</style>