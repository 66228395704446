var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.watchSubmitLoader)?_c('div',{staticStyle:{"display":"flex","justify-content":"center","margin-top":"250px"}},[_c('CustomLoaderComponent')],1):_c('div',{staticStyle:{"overflow":"hidden","font-family":"'VzNHGeDS-reg11'"}},[(this.getWhichRealm)?_c('div',{attrs:{"id":"vz-gh20"}}):_vm._e(),_c('div',{staticClass:"new_pwdless_parent_container"},[(
          _vm.submitLoader ||
          _vm.getTransactionLoader ||
          _vm.pollingLoader ||
          _vm.forgotLoader
        )?_c('BlockUI',{attrs:{"url":_vm.url}}):_vm._e(),(_vm.getNavHistory.length > 1)?_c('div',{staticClass:"new_pwdless_form_container"},[_c('div',{staticClass:"new_pwdless_box_container"},[_c('div',{staticClass:"new_pwdless_box"},[_c('div',{staticClass:"new_pwdless_content"},[(_vm.getCommonErrors.length)?_c('ErrorMessage',{attrs:{"message_details":_vm.getCommonErrors[0]}}):_vm._e(),(_vm.showBackButton)?_c('NavigationHistory'):_vm._e(),_c(_vm.getCurrentComponent.name,{tag:"component"})],1)])])]):_vm._e(),(_vm.getNavHistory.length == 1 && this.getWhichRealm)?_c('NewPwdLessLogin'):(_vm.getNavHistory.length == 1 && !this.getWhichRealm)?_c('MobilePwdLessLoginComponent'):_vm._e(),(_vm.showAdobeBaner)?_c('div',{class:[
          { box_alignments: _vm.isWideScreen },
          'container-2 right_banner',
        ]},[_vm._m(0)]):_vm._e()],1),(this.getWhichRealm)?_c('div',{attrs:{"id":"vz-gf20"}}):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pwd-default-promo1"},[_c('p',{staticClass:"pwd-default-promo"},[_vm._v(" Manage your business account online anytime, anywhere. ")])])
}]

export { render, staticRenderFns }