<template>
    <div style="overflow: hidden; font-family:'VzNHGeDS-reg11'">
        <div id="vz-gh20"></div>
        <div v-if="getUnifiedLoginAlerts.length > 0" class="marbottom20">
            <div class="row bannerMarginUnset" :class="bannerContainer()" v-for="j in getUnifiedLoginAlerts" :key="j">
                <div class="col-sm-12 newMessagesInfo" v-bind:class="getTypeOfAlert(j)" style="padding:0px;">
                    <div v-if="j.type == 'information' || j.type == 'success'" class="newMessagesInfo"
                        v-bind:class="getTypeOfAlert(j)" style="max-width:1271px">
                        <div class="messageWrapper">
                            <div class="messageIcon" style="padding:0px;margin:0px">
                                <div class="messageImage" :class="messageImage(j)"></div>
                            </div>
                            <p class="messageContent col-xs-9" style="padding:0px;margin:0px;color:#fff;"
                                v-html="j.msg"></p>
                            <div class="closeMessage col-xs-1" style="padding:0px;margin:0px">
                                <img class="closeMessageImg successbannercloseimg" :src="getCloseIcon()"
                                    @click="deleteAlert(j)" />
                            </div>
                        </div>
                    </div>
                    <div v-else class="newMessagesInfo" v-bind:class="getTypeOfAlert(j)" style="max-width:1271px">
                        <div class="messageWrapper">
                            <div class="messageIcon" style="padding:0px;margin:0px">
                                <div class="messageImage" :class="messageImage(j)"></div>
                            </div>
                            <p class="messageContent col-xs-9" style="padding:0px;margin:0px;color:#000000"
                                v-html="j.msg"></p>
                            <div class="helpblock" v-if="getHelpCheck">
                                <button class="helpbutton" @click="gethpop('gethelp')">
                                    <span class="buttontext"> Get Help</span>
                                </button>
                            </div>
                            <div class="closeMessage col-xs-1" style="padding:0px;margin:0px">
                                <img class="closeMessageImg errorbannercloseimg"
                                    src="@/common/assets/styles/corp/images/close_black.png" @click="deleteAlert(j)" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="emag-warning-container">
            <div class="emag-warning-message-1">
                <p class="emag-warning-text-style emag_legacy_btn_link">Warning : </p>
                <p class="emag-warning-text-style">This EMAG login option will no longer</p>
                <p class="emag-warning-text-style">available after 02/01/25.</p>
            </div>
            <div class="emag-warning-message-2">
                <p class="emag-warning-text-style">Before this date, you must update your user profiles with</p>
                <p class="emag-warning-text-style">My Business usernames using <u class="emag-warning-msg-ul-size">
                        Manage </u>  >  <u class="emag-warning-msg-ul-size"> User Profiles </u> </p>
            </div>
        </div>
        <div class="container martop20 iL-Container">

            <div class="col-sm-8 col-md-4 container-to-your-business-acc loginmar20" style="padding: 2px;">
                <h2 class="log-in">Log in to EMAG</h2>
                <p class="to-your-business-acc">using your My Verizon credentials</p>
                <div style="font-family:'VzNHGeDS-reg11'">
                    <BlockUI v-if="submitLoader" :url="url"></BlockUI>
                    <form class="signin" @submit.prevent="submitLoginForm">
                        <div class="user-id">
                            <label for class="-input-label">User ID or Verizon mobile number</label>
                            <input class="input-f" id="ilogin_userid" name="ilogin_userid" type="text" required
                                v-model="formData[0].input[0].value" maxlength="60" />
                            <!-- <p class="forgot-link" id="ilogin_forgot_userid" name="ilogin_forgot_userid" @click="FrUsername(); mybizzForget(false);">
                                Forgot user ID?
                            </p> -->
                        </div>
                        <div class="user-id">
                            <label for class="-input-label">Password</label>
                            <input class="input-f" id="ilogin_password" name="ilogin_password"
                                :type="show1 ? 'text' : 'password'" required v-model="formData[1].input[0].value"
                                maxlength="20" />
                            <span class="showBtn" v-if="!show1 && formData[1].input[0].value"
                                @click="show1 = !show1">Show</span>
                            <span class="showBtn" v-if="show1 && formData[1].input[0].value"
                                @click="show1 = !show1">Hide</span>
                            <!-- <p class="forgot-link" id="ilogin_forgot_password" name="ilogin_forgot_password" @click="FrPassword(); mybizzForget(false);">
                                Forgot password?
                            </p> -->
                        </div>

                        <div>
                            <button class="btn primary_black" :callback="submitLoginForm" id="ilogin_login_button"
                                name="ilogin_login_button"
                                style="width: 60%; margin: 12px 0px 30px;font-size: 16px !important; font-family: 'VzNHGeDS-bold11';"
                                type="submit">
                                Sign in
                            </button>
                        </div>

                        <div style="margin-bottom: 50px;">
                            <a class="emag-forgot-url-style" target="_blank" href="https://secure.verizon.com/account/forgot-password/start">Forgot your info ?</a>
                        </div>
                       
                        <!-- <div class="emag_legacy_page_btn_container">
                            <a class="emag_legacy_btn_link" target="_blank" href="https://ess.emag.vzw.com/emag/login">Click here for
                                temporary legacy login page</a>
                        </div> -->
                    </form>
                </div>
            </div>
        </div>
        <div id="vz-gf20"></div>
    </div>
</template>

<script>
import caret_blk from "@/common/assets/styles/corp/images/CTA-caret_blk.png";
import { mapGetters, mapActions } from 'vuex';
import { setPageDetails } from '../../../../public/adobeTagging';
import commonMixin from '@/common/mixin/commonMixins';
import { eventTracking, errorSet } from '../../../../public/adobeTagging';
import ClearWhite from "@/common/assets/styles/corp/images/clear_white.png";
import translations from "@/common/assets/json/messages1.json";

export default {
    name: "emagLoginComponent",
    components: {  },
    mixins: [commonMixin],
    data() {
        return {
            url: iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/circle-loaderWHITE_48x48.gif',
            vec2Registration: vec2_ent_portal_base_url,
            caret: caret_blk,
            resendWelcomeEmail: iamvar_resendWelcomeEmail,
            quick_login: iamvar_Quick_links,
            Mgmt_portal_login: iamvar_Mgmt_Portals,
            vcg_personal_login: iamvar_vcg_login,
            userId: '',
            userPassword: '',
            rememberMe: false,
            canSubmit: false,
            captchaOn: true,
            SignUpUrl: '',
            showClickHereMsg: false,
            idmRedirect: '',
            displaySignUpFalse: '',
            gotoPage: true,
            jCaptchaFlag: false,
            captchaText: null,
            show1: false
        }
    },
    async created() {
        setPageDetails('EMAG_Login:landing');
        gnavdl = {
            "bu": "smb",
            "appid": "unified"
        }
    },
    mounted() {
        var globalNav = document.createElement('script')
        globalNav.setAttribute('src', 'https://www.verizon.com/business/ngnav/smb.js')
        globalNav.setAttribute('async', 'async');
        document.head.appendChild(globalNav)
        this.$store.dispatch('login/updateGotoUrl', this.getGotoUrl());
        this.$store.dispatch('login/updateEncodedGotoUrl', this.getGotoUrl());
        this.removeExistingVBGCCookie();
        this.getSessionInfo();
        this.updateUrlOnMount(window.location.href);
        this.resetState();

        if (this.getParameterByName('success') == 'true' || this.getParameterByName('success') == true) {
            var alertMsg = translations.en.message["forgot_username_display"]
            var msg = [{ msg: alertMsg.replace('$0', this.getParameterByName('uid')), type: 'success' }]
            this.updateUnifiedLoginAlerts(msg)
        }
        window.localStorage.setItem("InitialRoute", window.location.href);
        //browser back button disabled
        window.onpopstate = () => {
            window.history.forward();
        };
        // Clearing the pwd fields for when sucessfull pwd reset.
        this.formData[1].input[0].value = ""
        this.updateClearFormFields(false);
    },
    methods: {
        ...mapActions('login', [
            'UpdatePopupValue',
            'submitEMAGLoginForm',
            'callRememberMe',
            'removeExistingVBGCCookie',
            'getSessionInfo',
            'updateUrlOnMount',
            'resetState',
            'updateCaptchaToken',
            'updateFederalPortalIndicator',
            'mybizzForget',
            'selectAccount',
            'updateUnifiedLoginAlerts',
            'updateClearFormFields'
        ]),
        ...mapActions('cvsstepup', ['initializeStepup', 'updateUserIdFlow']),
        submitLoginForm: function () {
            let payload = {
                formData: this.formData,
                rememberUser: this.rememberMe,
                additionalAuth: 'VBG_LOGIN'
            };
            this.submitEMAGLoginForm(payload);
        },
        getCloseIcon() {
            return ClearWhite;
        },

        getParameter(url) {
            let decodeUrl = decodeURIComponent(document.location)
            url = url.replace(/[\[\]]/g, '\\$&');
            var regex = new RegExp('[?&]' + url + '(=([^&#]*)|&|#|$)'),
                results = regex.exec(decodeUrl);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, ' '));
        },
        getTypeOfAlert(index) {
            //when error occurs take the user to the top screen where the orange banner is shown
            window.scrollTo(0, 0);
            return index.type
        },
        messageImage(index) {
            return index.type + "MessageImage"
        },
        deleteAlert(alert) {
            this.getUnifiedLoginAlerts.splice(this.getUnifiedLoginAlerts.indexOf(alert), 1)
        },
        gethpop(value) {
            this.UpdatePopupValue(value);
            $("#noAccessToOtpModal").modal({
                backdrop: "static",
            });
        },
        bannerContainer() {
            return (this.getUnifiedLoginAlerts[0].type == 'success' || this.getUnifiedLoginAlerts[0].msg.includes("Too many failed attempts")) ? 'bannerMargin' : ''
        }
    },
    computed: {
        ...mapGetters('login', [
            'getUnifiedLoginAlerts',
            "getUnifiedErrorCode",
            'submitLoader',
            'iamvar_enableCaptcha',
            'iamvar_idmUrl',
            'loginCaptchaKey',
            'getAlertType',
            'popupValue',
            'clearFormFields'
        ]),
        ...mapGetters('cvsstepup', ['currentStep']),
        ...mapGetters('forgotflow', ["usrName"]),
        formData: {
            get: function () {
                return this.$store.getters['login/formData'];
            },
            set: function (newVal) {
                this.$store.dispatch('login/updateFormData', newVal);
            }
        },
        gotoUrl: {
            get: function () {
                return this.$store.getters['login/gotoUrl'];
            },
            set: function (newVal) {
                this.$store.dispatch('login/updateGotoUrl', newVal);
                this.$store.dispatch('login/updateEncodedGotoUrl', newVal);
            }
        },
        getHelpCheck() {
            return (this.getUnifiedErrorCode == "ACCOUNT_LOCK_PREVENTION" || this.getUnifiedErrorCode == "ACCOUNT_LOCKED" || this.getUnifiedErrorCode == "ACCOUNT_PREVENTION" || this.getUnifiedErrorCode == "ACCOUNT_LOGIN_LOCKED" || this.getUnifiedErrorCode == "OTP_COUNTER_AND_PROFILE_INVALID_ATTEMPTS_COUNTE_MISMATCH") ? true : false
        },

    },
    watch: {
        show1: function (newVal) {
            if (newVal) {
                setTimeout(() => {
                    this.show1 = false
                }, 10000)
            }
        },
        usrName: function (newVal) {
            if (newVal != undefined || newVal != null) {
                console.log("username=", newVal)
                this.formData[0].input[0].value = newVal
            }
        },
        clearFormFields: function (newVal) {
            if (newVal) {
                // Clearing the form fields for when invalid.
                this.formData[0].input[0].value = ""
                this.formData[1].input[0].value = ""
                this.updateClearFormFields(!newVal)
            }
        },
    }
}

</script>

<style scoped>
input::-ms-reveal,
input::-ms-clear {
    display: none;
}

.successbannercloseimg {
    cursor: pointer;
    width: 13px;
    height: 13px;
}

.bannerMarginUnset {
    margin: unset;
}

.showBtn {
    position: absolute;
    right: 12px;
    margin-top: 12px;
    text-decoration: underline;
    cursor: pointer;
    height: 20px;
    color: #000000;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 20px;
}

.modal-content-gethelp {
    height: 440px;
}

.helpblock {
    Width: 170px;
    Height: 28px;
    position: absolute;
    margin-left: 485px;
}

.helpbutton {
    Width: 88px;
    Height: 28px;
    border-radius: 14px;
    border: solid #000000;
}

.buttontext {
    height: 16px;
    width: 56px;
    color: #000000;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    top: -1px;
    line-height: 16px;
    text-align: center;
}

.close-icon-container {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 14px;
}

#noAccessToOtpModal {
    z-index: 105000000;
}

.log-in {
    height: 48px;
    width: 386px;
    color: #000000;
    font-family: "VzNHGeDS-bold11", Arial, Helvetica, sans-serif;
    font-size: 48px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 48px;
    margin-bottom: 7px;
}

.container-to-your-business-acc {
    width: 100%;
    max-width: 384px;
}

.to-your-business-acc {
    height: 24px;
    width: 386px;
    color: #000000;
    /* font-family: "Verizon NHG eDS"; */
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 12px;
}

hr.vz-horizontal {
    margin: 12px 0 !important;
}

.user-id {
    height: 98px;
    /* width: 385px; */
    margin-bottom: 12px;
}

.user-captcha {
    height: 176px;
}

.captcha-blk {
    height: 46px;
    margin-bottom: 10px;
    background: linear-gradient(270deg, #00FFF0 0%, #FFB404 100%);
}

.-input-label {
    height: 16px;
    width: 100% !important;
    color: #000000;
    /* font-family: "Verizon NHG eTX"; */
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
}

.input-f {
    box-sizing: border-box;
    height: 44px;
    width: 100%;
    border: 1px solid #d8dada;
    background-color: #ffffff;
    margin-bottom: 14px;
    border-bottom: #000000 1px solid !important;
    padding: 6px 12px;
    font-size: 16px;
}

.captcha-txt-blk {
    position: absolute;
    margin-top: 24px;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.forgot-link {
    height: 20px;
    color: #000000;
    /* font-family: "Verizon NHG DS"; */
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 20px;
    float: right;
    cursor: pointer;
    text-decoration: underline;
}

input[type="checkbox"].tc {
    width: 20px;
    height: 20px;
    outline: 1px solid #000;
    margin-right: 10px;
    position: relative;
    left: unset;
}

p>label {
    height: 20px;
    /* width: 296px; */
    color: #000000;
    /* font-family: "Verizon NHG eDS"; */
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 20px;
}

.vz-login-link {
    height: 20px;
    /* width: 385px; */
    color: #000000;
    /* font-family: "Verizon NHG DS"; */
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 20px;
    display: table;
}

a {
    color: #000000;
    text-decoration: underline;
}

a:hover {
    color: #000000;
    text-decoration: underline;
    cursor: pointer;
}

input.tab {
    appearance: none;
    -webkit-appearance: none;
    border: none;
    display: none;
    background: transparent;
    visibility: hidden;
}

input.tab {
    display: inline-block;
    position: absolute;
    margin-left: -6px;
    height: 40px;
    width: 120px;
    cursor: pointer;
}

/* input.tab+label { display:none; } */
input.tab+label {
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    vertical-align: bottom;
    padding: 6px 24px 15px 0;
    margin: 0 0 24px;
    border-bottom: solid 1px #ccc;
    font-size: 16px !important;
}

input.tab+label:not(:hover) {
    color: #777;
}

input#usePush+label {
    padding-left: 24px;
    padding-right: 0;
}

input.tab:checked+label {
    border-bottom: solid 4px #d52b1e;
    padding: 6px 0 12px !important;
    color: #000;
    font-family: VzNHGeDS-bold11;
}

.newError {
    background: #ed7000 !important;
}

.emag_legacy_page_btn_container {
    text-align: center;
    margin-top: 30px;
}

.emag_legacy_btn_link {
    font-weight: bolder;
    color: #ffffff;
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    100% {
        color: #d52b1e;
    }
}

.emag-warning-container {
    margin-right: auto;
    margin-left: auto;
    width: max-content;
    margin-top: 30px;
    margin-bottom: 20px;
}

.emag-warning-message-1 {
    font-size: x-large;
    color: red;
}

.emag-warning-message-2 {
    font-size: large;
    margin-top: 25px;
    font-weight: bolder;
}

.emag-warning-text-style {
    margin-top: 8px;
    white-space: pre;
}

.emag-warning-msg-ul-size {
    text-underline-offset: 4px;
    text-decoration-thickness: 2px;
}
.emag-forgot-url-style {
    font-size: 16px !important;
    line-height: 20px !important;
    letter-spacing: 0.5px;
    color: #000000 !important;
    font-family: "VzNHGeTX-reg11";
}

.modal-dialog {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}

.modal-dialog {
    margin: 0px !important;
}

@media only screen and (min-width: 320px) and (max-width: 660px) {
    .helpblock {
        position: relative;
        margin-left: 30px;
        margin-top: 15px;
        float: left;
    }
}

@media only screen and (min-width: 320px) and (max-width: 1200px) {
    .default-promo {
        display: none;
    }

    .default-promo1 {
        display: none;
    }

    .modal-dialog {
        margin: 0px !important;
    }
}

@media (max-width: 540px) {
    .modal-dialog.modal-dialog-centered {
        height: 100% !important;
        margin: 0px !important;
        width: 100% !important;
    }

    .modal-content {
        width: 100%;
        height: 100%;
    }
}

@media (min-width: 270px) and (max-width: 400px) {
    .container-to-your-business-acc {
        width: 100%;
    }
}

@media (max-width: 320px) {

    /*.closeMessageImg{
                position: relative;
            }*/
    .messageWrapper {
        position: relative;
    }
}

@media (max-width: 320px) {
    .messageWrapper {
        position: relative;
    }

}

@media (min-width: 460px) {
    .iL-Container {
        width: 384px;
    }
}

@media (min-width: 768px) {
    .iL-Container {
        display: block;
    }
}

@media(min-width: 320px) and (max-width: 768px) {
    .loginmar20 {
        margin-top: -20px;
    }
}

@media (max-width: 330px) {
    .register-link {
        white-space: pre;
    }
}

@media (max-width: 280px) {
    .closeMessageImg {
        position: absolute;

    }
}

@media (min-width: 768px) {
    .errorbannercloseimg {
        width: 13px;
        height: 13px;
        margin-top: -12px;
        cursor: pointer;
    }
}

@media (max-width: 320px) {
    .errorbannercloseimg {
        width: 13px;
        height: 13px;
        cursor: pointer;
    }
}

@media (min-width: 375px) and (max-width: 720px) {
    .errorbannercloseimg {
        width: 13px;
        height: 13px;
        margin-top: -6px;
        cursor: pointer;
    }
}

@media (max-width: 425px) {
    .bannerMargin {
        margin: 8px 16px 0px;
    }
}
</style>