var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vbg-rsp-main-container"},[_c('div',[(_vm.isLoading || _vm.isOtpLoading)?_c('BlockUI',{attrs:{"url":_vm.url}}):_vm._e(),_c('div',{staticClass:"vbg-rsp-title-container"},[_c('h1',{staticClass:"vbg-rsp-title-h1"},[_vm._v(" "+_vm._s(_vm.$t("message.reset_security_add_verification_method_title"))+" ")])]),_c('div',[_c('p',{domProps:{"innerHTML":_vm._s(_vm.summary_desc)}})]),_c('div',{staticClass:"vbg-rsp-form-container"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit()}}},[_c('fieldset',{staticClass:"vbg-rsp-dropdown-container1"},[_c('div',{staticClass:"vbg-rsp-dropdown-container"},[_c('label',{staticClass:"vbg-ff-input-label",class:{ 'vbg-rsp-disabled-label': _vm.showVerifyOTP },attrs:{"for":"otp-dropdown"}},[_vm._v(" "+_vm._s(_vm.$t("message.login_otp_device_dropdown_label"))+" ")]),_c('div',{attrs:{"id":"drpdn"}},[_c('div',{ref:"dropdownRef",staticClass:"row dropdown dropdown-toggle",class:{
                  open: _vm.showDropdown,
                  'vbg-rsp-disabled-select': _vm.showVerifyOTP,
                },attrs:{"aria-haspopup":"true","aria-expanded":"false","tabindex":"1"},on:{"click":function($event){!_vm.showVerifyOTP && _vm.toggleDropdown()},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;!_vm.showVerifyOTP && _vm.toggleDropdown()}}},[_c('div',{staticClass:"dropDefaultLabel"},[_c('a',{attrs:{"id":"dropdownMenu1","aria-haspopup":"true","role":"button"}},[_c('span',{staticClass:"padleft10",class:{ 'vbg-rsp-disabled-label': _vm.showVerifyOTP }},[_vm._v(" "+_vm._s(_vm.selectedOtpMethod.label)+" ")])])]),_c('img',{staticClass:"vbg-rsp-dropdown-cheveron",attrs:{"src":_vm.downCaret}}),_c('ul',{ref:"dropdownListRef",staticClass:"dropdown-menu col-xs-12 px-0",attrs:{"aria-labelledby":"dropdownMenu1"}},_vm._l((_vm.otpDeviceList.isSmbUser ? _vm.otpMethodsForSmb : _vm.otpMethods),function(method,index){return _c('li',{key:method.id,ref:"selectDeviceList",refInFor:true,class:{
                      chosen: method.id === _vm.selectedOtpMethod.id,
                    },attrs:{"role":"presentation","id":method.id,"tabindex":index},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.deviceOptionSelected(method, 'enter')},function($event){return _vm.deviceListkeyupHandler($event, index)}],"click":function($event){return _vm.deviceOptionSelected(method, 'click')}}},[_c('a',{attrs:{"role":"menuitem"}},[_c('span'),_vm._v(" "+_vm._s(method.label)+" ")])])}),0)])])]),(_vm.selectedOtpType)?_c('div',{staticClass:"user-otp-input-container"},[_c('label',{staticClass:"vbg-ff-input-label",class:{ 'vbg-rsp-disabled-label': _vm.showVerifyOTP },attrs:{"for":"user-input-text"}},[_vm._v(" "+_vm._s(_vm.selectedOtpType === "email" ? "Email" : "10-digit phone number")+" ")]),_c('div',{staticClass:"vbg-rsp-icon-input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userInputText),expression:"userInputText"}],ref:"userInputTextRef",staticClass:"vbg-rsp-input-field",class:{
                  'vbg-rsp-disabled-input': _vm.showVerifyOTP,
                  'vbg-rsp-input-warning': !_vm.isValidText && _vm.userInputText,
                  // 'vbg-rsp-input-success': isValidText,
                },attrs:{"id":"user-input-text","name":"user-input-text","type":"text","disabled":_vm.showVerifyOTP,"tabindex":"2"},domProps:{"value":(_vm.userInputText)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;$event.preventDefault();},"input":[function($event){if($event.target.composing)return;_vm.userInputText=$event.target.value},function($event){return _vm.validateUserInput()}]}}),(!_vm.isValidText && _vm.userInputText)?_c('div',{staticClass:"vbg-rsp-warning-icon"},[_c('img',{attrs:{"src":_vm.warningIcon}})]):_vm._e(),(_vm.selectedOtpType && _vm.userInputText && !_vm.isValidText)?_c('div',[_c('p',{staticClass:"vbg-rsp-warning-msg",domProps:{"innerHTML":_vm._s(_vm.getWarningMessage)}})]):_vm._e()])]):_vm._e()]),_c('div',{staticClass:"vbg-sp-btn-continue"},[_c('button',{staticClass:"btn vbg-ff-btn-continue vbg-ff-btn-width140 vbg-rsp-add-btn spacing05",class:{
              'vbg-rsp-disabled-btn':
                _vm.showVerifyOTP || _vm.disableDeliveryBtn || _vm.isLoading,
            },attrs:{"id":"rsp-add-delivery-btn","name":"rsp-add-delivery-btn","type":"submit","disabled":_vm.showVerifyOTP || _vm.disableDeliveryBtn || _vm.isLoading,"tabindex":"3"}},[_vm._v(" Add delivery method ")]),_c('button',{staticClass:"btn vbg-ff-btn-cancel vbg-ff-btn-width140 vbg-rsp-add-btn spacing05",class:{ 'vbg-rsp-disabled-btn-cancel': _vm.showVerifyOTP },attrs:{"id":"rsp-cancel-delivery-btn","name":"rsp-cancel-delivery-btn","type":"button","disabled":_vm.showVerifyOTP,"tabindex":"4"},on:{"click":function($event){return _vm.cancelRecoveryFlow()}}},[_vm._v(" Cancel ")])])])]),(_vm.showVerifyOTP)?_c('div',{staticClass:"vbg-rsp-verify-method-container"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.OnOtpSubmit()}}},[_c('h2',{staticClass:"vbg-rsp-title-h2"},[_vm._v("Verify your delivery method")]),_c('p',[_vm._v(" Please enter the one-time passcode you received at "+_vm._s(_vm.maskedValue)+". "),_c('br'),_vm._v("Passcode is valid for 10 minutes. ")]),_c('div',{staticClass:"verify-input-container"},[_c('label',{staticClass:"vbg-ff-input-label",attrs:{"for":"onepasscode"}},[_vm._v("Enter one-time passcode")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.onepasscode),expression:"onepasscode"}],staticClass:"vbg-rsp-input-field",attrs:{"type":"password","id":"onepasscode","name":"onepasscode","minlength":"6","maxlength":"6","tabindex":"5"},domProps:{"value":(_vm.onepasscode)},on:{"keypress":_vm.onlyNumber,"input":function($event){if($event.target.composing)return;_vm.onepasscode=$event.target.value}}}),(_vm.onepasscode.length !== 0 && _vm.onepasscode.length !== 6)?_c('div',[_c('p',{staticClass:"vbg-rsp-warning-msg"},[_vm._v(" Please enter only 6-digits Passcode ")])]):_vm._e()]),_c('div',{staticClass:"vbg-sp-btn-continue"},[_c('button',{staticClass:"btn vbg-ff-btn-continue vbg-ff-btn-width140 btn-verify spacing05",class:{
              'vbg-ff-disabled': _vm.isOtpLoading || _vm.onepasscode.length !== 6,
            },attrs:{"type":"submit","disabled":_vm.isOtpLoading || _vm.onepasscode.length !== 6,"tabindex":"6","id":"rsp-verify-btn","name":"rsp-verify-btn"}},[_vm._v(" Verify ")]),_c('button',{staticClass:"btn vbg-ff-btn-cancel vbg-ff-btn-width140 btn-verify spacing05",attrs:{"type":"button","tabindex":"7","id":"rsp-cancel-verify-btn","name":"rsp-cancel-verify-btn"},on:{"click":function($event){return _vm.cancelVerifyOtp()}}},[_vm._v(" Cancel ")])]),_c('p',{staticClass:"vz-text-link resend-passcode-link"},[_c('a',{staticClass:"vz-text-link vbg-rsp-link",attrs:{"href":"#","id":"resendlink","tabindex":"8"},on:{"click":function($event){return _vm.onSubmit()}}},[_vm._v(" Resend passcode ")])]),_c('p',{staticClass:"vz-text-link cant-receive-passcode-link"},[_c('a',{staticClass:"vz-text-link vbg-rsp-link",attrs:{"href":"#","id":"launchInfo","tabindex":"9"},on:{"click":function($event){return _vm.openModel()}}},[_vm._v(" I can't receive a one-time passcode ")])]),_c('div',{staticClass:"modal fade",attrs:{"id":"noAccessToOtpModal"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content modal-content-container"},[_c('div',{staticClass:"modal-container"},[_c('div',{staticClass:"close-icon-container",attrs:{"tabindex":"1"}},[_c('a',{attrs:{"data-dismiss":"modal"},on:{"click":function($event){return _vm.closeModal()}}},[_c('img',{staticStyle:{"height":"20px","width":"20px"},attrs:{"src":require("@/common/assets/styles/corp/images/close_black.png")}})])]),_vm._m(0),_c('div',{staticClass:"modal-btn-container martop100"},[_c('button',{staticClass:"btn primary_black marright20 martop50 oauth-close-button bttn-container vbg-rel-bottom-40 spacing05",attrs:{"type":"button","data-dismiss":"modal","tabindex":"3","id":"rsp-close-modal-btn","name":"rsp-close-modal-btn"},on:{"click":function($event){return _vm.closeModal()}}},[_c('span',[_vm._v("Close")])])])])])])])])]):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"window-content mar-bottom50"},[_c('div',[_c('h2',{staticClass:"martop0 marbottom20 modal-content-title"},[_vm._v(" Can't receive a one time passcode? ")]),_c('p',{staticClass:"modal-content-description"},[_vm._v(" Please try another delivery method, contact your company administrator, or "),_c('a',{staticClass:"vbg-ff-focus",staticStyle:{"text-decoration":"underline","cursor":"pointer"},attrs:{"href":"https://www.verizon.com/business/support/","target":"_blank","tabindex":"2"}},[_vm._v("visit our support page.")])])])])
}]

export { render, staticRenderFns }