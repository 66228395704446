
<template>
  <div>
    <div v-if="!cardData.flag" class="loader"></div>
    <div v-else class="align-center">
      <span v-if="cardData?.status" class="icon-size">&#9989;</span>
      <span v-else class="icon-size">&#10060;</span>
      <div class="media">
        <!-- <span style="display: block; margin-bottom: 10px;">{{ cardData?.name }}</span> -->
        <b>Build Info ({{ cardData.buildinfo }})</b>
      </div>
    </div>
  </div>
</template>
<script>
import commonMixin from "./mixins/common-mixin.js";
export default {
  name: "VbgHealthCheckCard",
  props: ["card", "env", "subEnv", "index", "tabName"],
  data() {
    return {
      cardData: { ...this.card, status: false, buildinfo: null },
    };
  },
  mixins: [commonMixin],
  computed: {},

  mounted() {
    console.log("mountingS");
    this.fetchData(this.tabName);
  },
};
</script>
<style scoped>
.media {
  text-align: left;
  margin-left: 10px;
  margin-top: 0px !important;
}
.align-center {
  display: flex;
  align-items: center;
}
.icon-size {
  font-size: 20px !important;
  margin-right: 5px;
}
.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid red;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>